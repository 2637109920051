import { API, graphqlOperation } from 'aws-amplify';
import { listTrees } from './../graphql/queries';

import Tree from '../models/Tree';

const trees: Tree[] = [
    {
        name: 'mango',
        species: 'Mangifera indica',
        cost: 18,
        description: 'The Mango thrives in tropical and subtropical areas. It produces great quantities of fruits for people to consume and trade for cash.',
        carbonOffsetKgPerYear: 70,
        images: ['https://greenor.s3.us-east-2.amazonaws.com/img/mango_1.jpeg',
            'https://greenor.s3.us-east-2.amazonaws.com/img/mango_2.jpeg',
            'https://greenor.s3.us-east-2.amazonaws.com/img/mango_3.jpeg'],
        foodSecurityScore: 8,
        economicDevelopmentScore: 10,
        carbonScore: 6,
        environmentProtectionScore: 6
    },
    {
        name: 'avocado',
        species: 'Persea americana',
        cost: 14,
        description: 'The Avocado tree is known for its fruit, which contains a rich source of energy and nutrients. Avocado is planted for fruit cultivation and the shade that it provides for other crops.',
        carbonOffsetKgPerYear: 50,
        images: ['https://greenor.s3.us-east-2.amazonaws.com/img/avocado_1.jpeg',
                    'https://greenor.s3.us-east-2.amazonaws.com/img/avocado_2.jpeg',
                    'https://greenor.s3.us-east-2.amazonaws.com/img/avocado_3.jpeg'],
        foodSecurityScore: 7,
        economicDevelopmentScore: 10,
        carbonScore: 8,
        environmentProtectionScore: 7
    }];

    class TreesData {
        constructor() {
            this.defaultTree = trees[1];
        }
        defaultTree: Tree;
        getTreeByName(name: string) {
            return trees.find((tree) => {
                return tree.name.toLowerCase() == name.toLowerCase();
            })
        }

        async getTrees() {
            try {
                const manufacturers = await API.graphql(graphqlOperation(listTrees, {
                    limit: 50
                }));
                const rst = manufacturers['data']['listTrees']['items'];
                return rst;
            } catch (err) {
                console.error('Error happened getting trees:', err);
                return [];
            }
        }
    }

export default TreesData;