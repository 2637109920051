import PhoneCarrier from '../models/PhoneCarrier';

const carriers: PhoneCarrier[] = [
    {
        name: 'Unlocked',
        logoUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/apple_logo.jpeg'
    },
    {
        name: 'T-Mobile',
        logoUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/google_logo.jpeg'
    },
    {
        name: 'Google Fi',
        logoUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/samsung_logo.jpeg'
    },
    {
        name: 'AT&T',
        logoUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/lg_logo.jpeg'
    },
    {
        name: 'Verizon',
        logoUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/motorola_logo.jpeg'
    },
    {
        name: 'Sprint',
        logoUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/motorola_logo.jpeg'
    },
    {
        name: 'Other',
        logoUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/motorola_logo.jpeg'
    }];

class PhoneCarriersData {

    getCarriers() {
        return carriers;
    }
}

export default PhoneCarriersData;