import { API, graphqlOperation } from 'aws-amplify';
import { listGreenorManufacturers } from './../graphql/queries';
import { createGreenorManufacturer } from './../graphql/mutations';

/*const manufacturers: Manufacturer[] = [
    {
        name: 'Apple',
        logoUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/apple_logo.jpeg'
    },
    {
        name: 'Google',
        logoUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/google_logo.jpeg'
    },
    {
        name: 'Samsung',
        logoUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/samsung_logo.jpeg'
    },
    {
        name: 'LG',
        logoUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/lg_logo.jpeg'
    },
    {
        name: 'Motorola',
        logoUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/motorola_logo.jpeg'
    }];*/

class ManufacturersData {

    /*getManufacturesTest() {
        return manufacturers;
    }*/
    async getManufacturers() {
        try {
            const manufacturers = await API.graphql(graphqlOperation(listGreenorManufacturers, {
                limit: 20
            }));
            const rst = manufacturers['data']['listGreenorManufacturers']['items'];
            return rst;
        } catch (err) {
            return [];
        }
    }
    async createManufacturer(name: string, url: string, isActive=true) {
        name = name.toLowerCase();
        try {
            const created = await API.graphql(graphqlOperation(createGreenorManufacturer, {input: {
                name: name,
                logoUrl: url,
                isActive: isActive
            }}));
            return created;
        } catch(err) {
            return null;
        }
    }
 }

export default ManufacturersData;