import React from 'react';
import { IonList, IonItem, IonLabel, IonInput, IonSelectOption, IonSelect, IonRow, IonCol } from '@ionic/react';
import { useEffect, useState } from 'react';
import ValueEstimateComponent from './ValueEstimate';
import ManufacturersData from '../data/ManufacturersData';
import Manufacturer from '../models/Manufacturer';

const manufacturerData = new ManufacturersData();

interface ModelProps {
    deviceModelObject: any;
    availableSizes: Array<string>;
    updateName: (e: any) => void;
    updateCategory: (e: any) => void;
    updateManufacturer: (e: any) => void;
    updateImageUrl: (e: any) => void;
    updateAvailableColors: (e: any) => void;
    updateAvailableMemorySizes: (e: any) => void;
    updateSizesValue: (e: any) => void;
}

const categories = [{ name: 'Phones', value: 'phones' }, { name: 'Laptops', value: 'laptops' }, { name: 'Tablets', value: 'tablets' }];

const DeviceModelInputsComponent: React.FC<ModelProps> = ({ deviceModelObject, updateName, updateCategory, updateManufacturer,
    updateImageUrl, updateAvailableColors, updateAvailableMemorySizes, availableSizes, updateSizesValue }) => {

    const [manufacturers, setManufacturers] = useState(new Array<Manufacturer>());
    useEffect(() => {
        manufacturerData.getManufacturers()
            .then(results => {
                setManufacturers(results);
            });
    }, []);
    return (
        <IonList>
            <IonItem>
                <IonLabel position="stacked">
                    Name * <span className='error-message' id='nameErrorMessage'>(A name is required)</span>:
                </IonLabel>
                <IonInput value={deviceModelObject.name} onIonChange={updateName} type='text' placeholder='Enter device name'></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">
                    Category * <span className='error-message' id='categoryErrorMessage'>(A Category is required)</span>:
                </IonLabel>
                <IonSelect onIonChange={updateCategory} id='categorySelect' placeholder='Select a category'>
                    {categories.map((cat) => {
                        return (<IonSelectOption value={cat.value} key={cat.name}>{cat.name}</IonSelectOption>)
                    })}
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">
                    Manufacturer * <span className='error-message' id='manufacturerErrorMessage'>(A manufacturer is required)</span>:
                </IonLabel>
                <IonSelect onIonChange={updateManufacturer} id='manufacturerSelect' placeholder='Select a manufacturer'>
                    {manufacturers.map((manu) => {
                        return (<IonSelectOption value={manu.name} key={manu.name}>{manu.name}</IonSelectOption>)
                    })}
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">
                    Image URL *<span className='error-message' id='urlErrorMessage'>(Image URL is required)</span>:
                </IonLabel>
                <IonInput value={deviceModelObject.imageUrl} onIonChange={updateImageUrl} type='text' placeholder=''></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">
                    Available colors * <span className='error-message' id='colorErrorMessage'>(Available colors are required)</span>:
                </IonLabel>
                <IonInput value={deviceModelObject.availableColors} onIonChange={updateAvailableColors} type='text' placeholder='Enter comma-separated colors(eg. Black,White)'></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">
                    Available memory sizes * <span className='error-message' id='sizeErrorMessage'>(Available sizes are required)</span>:
                </IonLabel>
                <IonInput value={deviceModelObject.availableMemorySizes} onIonChange={updateAvailableMemorySizes} type='text' placeholder='Enter comma-separated sizes(eg. 128,256)'></IonInput>
            </IonItem>
            <IonItem>
                {availableSizes.map(size => {
                    return (
                        <ValueEstimateComponent size={size} updateSizesValue={updateSizesValue} />
                    )
                })}
            </IonItem>
        </IonList>
    );
};

export default DeviceModelInputsComponent;
