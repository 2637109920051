import TreesData from "../data/TreesData";

const trees = new TreesData();
const defaultTree = trees.defaultTree;
const carbonAbsoptionPeriodYears = 10;
const kgInTon = 1000;

const Offset = {
    getEstimatedNumberOfTrees: (valueInDollars: any) => {
        let numberOfTrees: number = 0;
        valueInDollars = parseInt(valueInDollars);
        if (typeof (valueInDollars) != 'number' || valueInDollars < 0) {
            return numberOfTrees;
        } else {
            numberOfTrees = Math.round(valueInDollars / defaultTree.cost);
        }
        return numberOfTrees;
    },
    getEstimiatedCarbonOffset: (estimatedNumTree: number) => {
        return Math.round((defaultTree.carbonOffsetKgPerYear * carbonAbsoptionPeriodYears *
            estimatedNumTree) / kgInTon);
    }
}

export default Offset;