import React from 'react';
import { IonText, IonSegment, IonSegmentButton, IonLabel } from '@ionic/react';


interface SelectDeviceSizeProps {
    memorySizes?: Array<string>;
    callback: Function;
}

const SelectDeviceSize: React.FC<SelectDeviceSizeProps> = ({ memorySizes, callback }) => {
    return (
        <div className='section'>
            <IonText>
                <h5>Select size:</h5>
            </IonText>
            <IonSegment scrollable onIonChange={e => callback(e.detail.value)} color='secondary'>
                {memorySizes?.map(size => {
                    return (
                        <IonSegmentButton key={size} value={size}>
                            <IonLabel>{size}GB</IonLabel>
                        </IonSegmentButton>
                    );
                })}
            </IonSegment>
        </div>
    );
};

export default SelectDeviceSize;
