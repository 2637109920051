import React from 'react';
import { IonTitle, IonRow, IonGrid, IonAccordionGroup, IonAccordion, IonItem, IonLabel } from '@ionic/react';
import HowItWorksComponent from './HowItWorks';

const FAQComponent: React.FC = () => {
    return (
        <div>
            <IonGrid>
                <IonRow class="ion-justify-content-center ion-text-center">
                    <h1>FAQ:</h1>
                </IonRow>
            </IonGrid>
            <IonAccordionGroup>
                <IonAccordion value='0'>
                    <IonItem slot='header'>
                        <IonLabel>What is Greenor.org?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        Greenor.org is a non-profit organization whose mission is to bring about climate justice to poor smallholder farmers.
                    </div>
                </IonAccordion>
                <IonAccordion value='00'>
                    <IonItem slot='header'>
                        <IonLabel>How does it work?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        <HowItWorksComponent />
                    </div>
                </IonAccordion>
                <IonAccordion value='1'>
                    <IonItem slot='header'>
                        <IonLabel>Are donations tax deductible?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        Yes, once we receive the donation, we send you a donation receipt to use for tax deduction.
                    </div>
                </IonAccordion>
                <IonAccordion value='2'>
                    <IonItem slot='header'>
                        <IonLabel>I don't see my device on the listed devices, what should I do?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        If you believe your device has value and it's not listed, you can select the closest similar device from the listed ones and send it in; once we receive it we will evaluate it and send you a receipt corresponding to its assessed value.
                    </div>
                </IonAccordion>
                <IonAccordion value='3'>
                    <IonItem slot='header'>
                        <IonLabel>How to erase all of my iPhone's data and settings?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        <p>To protect your data and personal information, erase all of data and settings before sending your device to Greenor.org. We'll still double check your device to make sure it has been reset.</p>
                        <p>Before you erase your device, make a backup of your iPhone's data via iCloud or by connecting your iPhone to the computer you normally sync with.</p>
                        <p>Following are steps to reset your device:</p>

                        <p>1. Tap the settings (gear icon) from the home screen (or whatever page it happens to be located on your iPhone).</p>
                        <p>2. Tap the "General" settings menu item.</p>
                        <p>3. Choose the "Reset": menu item.</p>
                        <p>4. Tap on the "Erase All Contents and Settings" menu item.</p>
                        <p>In case your device doesn't power on or you forget to erase it before sending it, you can still erase it:</p>
                        <p>Sign in to <a href='https://icloud.com/#find' target='blank'>icloud.com/#find</a> with your Apple ID (the one you use with iCloud), then click Find My iPhone.</p>
                        <p>Click All Devices, then select the device you want to erase.</p>
                        <p>In the device's Info window, click Erase(device).</p>
                        <p>To erase:</p>
                        <p>Enter your Apple ID password. If the device you're erasing has iOS 7 or later, enter a phone number and message. The number and message will be displayed on the screen after the device is erased.</p>
                        <p>If your device is online, the remote erase begins. A confirmation email is sent to your Apple ID email address.</p>
                        <p>If your device is offline, the remote erase begins the next time it's online.</p>
                    </div>
                </IonAccordion>
                <IonAccordion value='4'>
                    <IonItem slot='header'>
                        <IonLabel>How to deactivate "Find My iPhone" before sending in the device?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        <p>Locate and tap the "Settings" icon on your device's home screen.</p>
                        <p>Tap "iCloud" in the Settings menu.</p>
                        <p>If Find My iPhone is ON, tap the slider to turn it OFF.</p>
                        <p>If you are prompted to for a password, enter your iCloud password and tap "Turn Off" to confirm.</p>
                        <p>Note: if you already reset your device, Find My iPhone was turned off.</p>
                    </div>
                </IonAccordion>
                <IonAccordion value='5'>
                    <IonItem slot='header'>
                        <IonLabel>How to deactivate a phone or another device?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        <p>Before sending your device, please make sure it has been deactivated from your service provider.</p>
                        <p>To deactivate your phone or other device, you must contact your wireless service provider.</p>
                    </div>
                </IonAccordion>
                <IonAccordion value='05'>
                    <IonItem slot='header'>
                        <IonLabel>How do I deactivate iMessage so I receive messages on my new phone?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        <p>In some cases, moving from an Apple device to a different type of device can cause problems receiving SMS messages.This is due to the Apple iMessage setup.</p>
                        <p>To activate: Tap Settings from the home screen. Tap Messages. Move the iMessage slider to OFF.</p>
                        <p>In case you no longer have your phone, you could always contact Apple support and they will help deactivate the device.</p>
                    </div>
                </IonAccordion>
                <IonAccordion value='6'>
                    <IonItem slot='header'>
                        <IonLabel>Can I still donate a device if I don't have all the accessories?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        <p>Yes, accessories are not necessary; you can donate a device even if a charger or any other accessory is missing.</p>
                    </div>
                </IonAccordion>
                <IonAccordion value='8'>
                    <IonItem slot='header'>
                        <IonLabel>How's the value of the device determined?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        <p>We use pricing data from online resellers and manufacturers.</p>
                    </div>
                </IonAccordion>
                <IonAccordion value='08'>
                    <IonItem slot='header'>
                        <IonLabel>Do I need to remove the SIM card from my phone or iPad?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        <p>We recommend that you remove SIM card before sending in the device. SIM cards contain sensitive information and to protect your privacy, we recommend that you remove it.</p>
                    </div>
                </IonAccordion>
                <IonAccordion value='9'>
                    <IonItem slot='header'>
                        <IonLabel>How to remove SIM card?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        <p>Locate the tray in the right edge of the casing. Gently insert a SIM card removal tool or a straightened paperclip into the pin-sized hole in the SIM card tray. Once you release the catch in bottom of the hole, the SIM tray will eject from the phone body. Remove your card from the tray and reinsert the tray so it is secure and flush with the casing.</p>
                    </div>
                </IonAccordion>
                <IonAccordion value='10'>
                    <IonItem slot='header'>
                        <IonLabel>How to prepare the device before shipping it?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        <p>You must:</p>
                        <p>Deactivate service.</p>
                        <p>If your device has cellular service, remove SIM card or deactivate through your carrier.</p>
                        <p>Turn Off Device Tracking (i.e. Find My iPhone, Samsung Reactivation Lock) and remove password</p>
                        <p>For Apple device, turn it off in the iCloud settings menu. For Samsung phones, check the Security settings.</p>
                        <p>We recommend:</p>
                        <p>Save your data</p>
                        <p>Backup your photos and files. If your device has an SD card, remove it. We will remove all personal data from the device.</p>
                        <p>If you're switching from an Apple device to something else, deactivate iMessage to ensure you continue to receive message on your new phone:</p>
                        <p>Tap Settings, then Messages, and move the iMessage slider to OFF.</p>

                    </div>
                </IonAccordion>
                <IonAccordion value='11'>
                    <IonItem slot='header'>
                        <IonLabel>How do I ship my device?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        <p>Print the shipping label and pack your device(s) securely in a box or padded envelope to prevent any damages from occurring in-transit. You can drop off your trade-in item(s) at any USPS store or eligible USPS drop-off point.</p>
                    </div>
                </IonAccordion>
            </IonAccordionGroup>
        </div>
    );
};

export default FAQComponent;
