/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGreenorManufacturer = /* GraphQL */ `
  mutation CreateGreenorManufacturer($input: CreateGreenorManufacturerInput!) {
    createGreenorManufacturer(input: $input) {
      name
      logoUrl
      isActive
    }
  }
`;
export const updateGreenorManufacturer = /* GraphQL */ `
  mutation UpdateGreenorManufacturer($input: UpdateGreenorManufacturerInput!) {
    updateGreenorManufacturer(input: $input) {
      name
      logoUrl
      isActive
    }
  }
`;
export const deleteGreenorManufacturer = /* GraphQL */ `
  mutation DeleteGreenorManufacturer($input: DeleteGreenorManufacturerInput!) {
    deleteGreenorManufacturer(input: $input) {
      name
      logoUrl
      isActive
    }
  }
`;
export const createGreenorDeviceModel = /* GraphQL */ `
  mutation CreateGreenorDeviceModel($input: CreateGreenorDeviceModelInput!) {
    createGreenorDeviceModel(input: $input) {
      manufacturerName
      name
      category
      imageUrl
      availableColors
      availableMemorySizes
      valueEstimatesByMemoryUnlocked
      valueEstimatesByMemoryLocked
      valueEstimatesByMemory
    }
  }
`;
export const updateGreenorDeviceModel = /* GraphQL */ `
  mutation UpdateGreenorDeviceModel($input: UpdateGreenorDeviceModelInput!) {
    updateGreenorDeviceModel(input: $input) {
      manufacturerName
      name
      category
      imageUrl
      availableColors
      availableMemorySizes
      valueEstimatesByMemoryUnlocked
      valueEstimatesByMemoryLocked
      valueEstimatesByMemory
    }
  }
`;
export const deleteGreenorDeviceModel = /* GraphQL */ `
  mutation DeleteGreenorDeviceModel($input: DeleteGreenorDeviceModelInput!) {
    deleteGreenorDeviceModel(input: $input) {
      manufacturerName
      name
      category
      imageUrl
      availableColors
      availableMemorySizes
      valueEstimatesByMemoryUnlocked
      valueEstimatesByMemoryLocked
      valueEstimatesByMemory
    }
  }
`;
export const createGreenorContactUs = /* GraphQL */ `
  mutation CreateGreenorContactUs($input: CreateGreenorContactUsInput!) {
    createGreenorContactUs(input: $input) {
      email
      timestamp
      name
      subject
      message
      phoneNumber
    }
  }
`;
export const updateGreenorContactUs = /* GraphQL */ `
  mutation UpdateGreenorContactUs($input: UpdateGreenorContactUsInput!) {
    updateGreenorContactUs(input: $input) {
      email
      timestamp
      name
      subject
      message
      phoneNumber
    }
  }
`;
export const deleteGreenorContactUs = /* GraphQL */ `
  mutation DeleteGreenorContactUs($input: DeleteGreenorContactUsInput!) {
    deleteGreenorContactUs(input: $input) {
      email
      timestamp
      name
      subject
      message
      phoneNumber
    }
  }
`;
export const createTrees = /* GraphQL */ `
  mutation CreateTrees($input: CreateTreesInput!) {
    createTrees(input: $input) {
      name
      species
      description
      cost
      carbonOffsetKgPerYear
      foodSecurityScore
      economicDevelopmentScore
      carbonScore
      environmentProtectionScore
      images
    }
  }
`;
export const updateTrees = /* GraphQL */ `
  mutation UpdateTrees($input: UpdateTreesInput!) {
    updateTrees(input: $input) {
      name
      species
      description
      cost
      carbonOffsetKgPerYear
      foodSecurityScore
      economicDevelopmentScore
      carbonScore
      environmentProtectionScore
      images
    }
  }
`;
export const deleteTrees = /* GraphQL */ `
  mutation DeleteTrees($input: DeleteTreesInput!) {
    deleteTrees(input: $input) {
      name
      species
      description
      cost
      carbonOffsetKgPerYear
      foodSecurityScore
      economicDevelopmentScore
      carbonScore
      environmentProtectionScore
      images
    }
  }
`;
export const createGreenorDonation = /* GraphQL */ `
  mutation CreateGreenorDonation($input: CreateGreenorDonationInput!) {
    createGreenorDonation(input: $input) {
      donationId
      timestamp
      donor
      email
      phoneNumber
      value
      estimatedNumberOfTrees
      estimatedCarbonOffset
      address
      devices
      shippingLabelUrl
    }
  }
`;
export const updateGreenorDonation = /* GraphQL */ `
  mutation UpdateGreenorDonation($input: UpdateGreenorDonationInput!) {
    updateGreenorDonation(input: $input) {
      donationId
      timestamp
      donor
      email
      phoneNumber
      value
      estimatedNumberOfTrees
      estimatedCarbonOffset
      address
      devices
      shippingLabelUrl
    }
  }
`;
export const deleteGreenorDonation = /* GraphQL */ `
  mutation DeleteGreenorDonation($input: DeleteGreenorDonationInput!) {
    deleteGreenorDonation(input: $input) {
      donationId
      timestamp
      donor
      email
      phoneNumber
      value
      estimatedNumberOfTrees
      estimatedCarbonOffset
      address
      devices
      shippingLabelUrl
    }
  }
`;
export const createGreenorShipment = /* GraphQL */ `
  mutation CreateGreenorShipment($input: CreateGreenorShipmentInput!) {
    createGreenorShipment(input: $input) {
      donationId
      status
      labelUrl
      trackingNumber
      trackingUrl
      ETA
      isTest
      rate
      rateAmount
      provider
      currency
      objectState
      wholeObject
    }
  }
`;
export const updateGreenorShipment = /* GraphQL */ `
  mutation UpdateGreenorShipment($input: UpdateGreenorShipmentInput!) {
    updateGreenorShipment(input: $input) {
      donationId
      status
      labelUrl
      trackingNumber
      trackingUrl
      ETA
      isTest
      rate
      rateAmount
      provider
      currency
      objectState
      wholeObject
    }
  }
`;
export const deleteGreenorShipment = /* GraphQL */ `
  mutation DeleteGreenorShipment($input: DeleteGreenorShipmentInput!) {
    deleteGreenorShipment(input: $input) {
      donationId
      status
      labelUrl
      trackingNumber
      trackingUrl
      ETA
      isTest
      rate
      rateAmount
      provider
      currency
      objectState
      wholeObject
    }
  }
`;
export const createGreenorDonor = /* GraphQL */ `
  mutation CreateGreenorDonor($input: CreateGreenorDonorInput!) {
    createGreenorDonor(input: $input) {
      email
      name
      inHonorOf
      donationAmount
      timestamp
      frequency
    }
  }
`;
export const updateGreenorDonor = /* GraphQL */ `
  mutation UpdateGreenorDonor($input: UpdateGreenorDonorInput!) {
    updateGreenorDonor(input: $input) {
      email
      name
      inHonorOf
      donationAmount
      timestamp
      frequency
    }
  }
`;
export const deleteGreenorDonor = /* GraphQL */ `
  mutation DeleteGreenorDonor($input: DeleteGreenorDonorInput!) {
    deleteGreenorDonor(input: $input) {
      email
      name
      inHonorOf
      donationAmount
      timestamp
      frequency
    }
  }
`;
