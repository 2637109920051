import { API, graphqlOperation } from 'aws-amplify';
import { listGreenorManufacturers } from './../graphql/queries';
import { createGreenorContactUs } from './../graphql/mutations';

class ContactUsData {

    async getContacts() {
        try {
            const manufacturers = await API.graphql(graphqlOperation(listGreenorManufacturers, {
                limit: 20
            }));
            const rst = manufacturers['data']['listGreenorManufacturers']['items'];
            return rst;
        } catch (err) {
            console.error('Error happened getting contacts:', err);
            return [];
        }
    }
    async createContact(email: string, name: string, phoneNumber: string, subject: string, message: string) {
        name = name.toLowerCase();
        try {
            const created = await API.graphql(graphqlOperation(createGreenorContactUs, {
                input: {
                    email: email,
                    timestamp: new Date().getTime().toString(),
                    name: name,
                    phoneNumber: phoneNumber,
                    subject: subject,
                    message: message
                }
            }));
            return created;
        } catch (err) {
            return null;
        }
    }
}

export default ContactUsData;