import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';
import Donate from './pages/Donate';
import SelectManufacturer from './pages/SelectManufacturer';
import SelectModel from './pages/SelectModel';
import DeviceDetails from './pages/DeviceDetails';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import Shipping from './pages/Shipping';
import DonateSummary from './pages/DonateSummary';
import Home from './pages/Home';
import DeviceModelCRUD from './pages/DeviceModelCRUD';
import HowItWorks from './pages/HowItWorks';
import FAQ from './pages/FAQ';
import Contact from './pages/Contact';
import Trees from './pages/Trees';
import Agroforestry from './pages/Agroforestry';
import Sitemap from './pages/Sitemap';
import MoneyAmount from './pages/MoneyAmount';
import ThankYou from './pages/ThankYou';
import ClimateJustice from './pages/blog/ClimateJustice';
import ClimateJusticeFarmers from './pages/blog/ClimateJusticeFarmers';

/* Theme variables */
/*import './theme/variables.css';*/

setupIonicReact();
Amplify.configure(awsconfig);

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Home />
            </Route>
            <Route path="/page/:name" exact={true}>
              <Page />
            </Route>
            <Route path="/donate" exact={true}>
              <Donate />
            </Route>
          <Route path="/donate/:deviceCategory" component={SelectManufacturer}></Route>
          <Route path="/donate/:manufacturerName/:deviceCategory" component={SelectModel}></Route>
          <Route path="/donate/:manufacturerName/:deviceCategory/:name" component={DeviceDetails}></Route>
          <Route path="/donate/shipping-information" component={Shipping}></Route>
          <Route path="/donate/summary" component={DonateSummary}></Route>
          <Route path="/create/model" component={DeviceModelCRUD}></Route>
          <Route path="/how-it-works" component={HowItWorks}></Route>
          <Route path="/faq" component={FAQ}></Route>
          <Route path="/contact" component={Contact}></Route>
          <Route path="/fruit-trees" component={Trees}></Route>
          <Route path="/agroforestry" component={Agroforestry}></Route>
          <Route path="/sitemap" component={Sitemap}></Route>
          <Route path="/donate/money" component={MoneyAmount}></Route>
          <Route path="/donate/thank-you" component={ThankYou}></Route>
          <Route path="/blog/climate-justice-for-smallholder-farmers" component={ClimateJusticeFarmers}></Route>
          <Route path="/blog/climate-justice" component={ClimateJustice}></Route>

          </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
