import {
    IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonRow, IonCol, IonGrid
} from '@ionic/react';
import { NavButtons } from '../components/NavButtons';
import { Footer } from '../components/Footer';
import ImportExternalScripts from './../hooks/ImportExternalScripts';
import { Helmet } from "react-helmet";
import Amount from './../components/Amount';
import AmountImpact from '../components/AmountImpact';

import './MoneyAmount.css';

import Title from '../components/Title';
import { useState } from 'react';
import StripePayment from '../components/StripePayment';
import Frequency from '../models/Frequency';

const MoneyAmount: React.FC = () => {

    const [showAmountComponent, setShowAmountComponent] = useState(true);
    const [showPaymentOptionsComponent, setShowPaymentOptionsComponent] = useState(false);
    const [showStripePayment, setShowStripePayment] = useState(false);
    const [donationAmount, setDonationAmount] = useState(36);
    const [frequency, setFrequency] = useState(Frequency.MONTHLY);
    const [inHonorOf, setInHonorOf] = useState('')

    let inHonorChecked = false;

    const donationAmountChanged = (val: number) => {
        setDonationAmount(val);
    }

    const giveFrequencyChanged = (e: any) => {
        if (e.target.value == 'once') {
            setFrequency(Frequency.ONCE);
        } else {
            setFrequency(Frequency.MONTHLY);
        }
    }

    const inHonorChanged = (e: any) => {
        setInHonorOf(e.target.value);
    }

    const inHonorCheckerChanged = (val: boolean) => {
        inHonorChecked = val;
    }

    const amountConfirmed = () => {
        setShowAmountComponent(false);
        setShowStripePayment(true);
    }

    const goBackToAmount = () => {
        setShowStripePayment(false);
        setShowAmountComponent(true);
    }

    /*const goToStripePayment = () => {
        setShowPaymentOptionsComponent(false);
        setShowStripePayment(true);
    }

    const goBackToPaymentOptions = () => {
        console.log('goBackToPaymentOptions clicked..');
        setShowStripePayment(false);
        setShowPaymentOptionsComponent(true);
    }*/

    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/analytics.js" });
    return (
        <IonPage>

            <Helmet>
                <title>Thank you for showing some love.</title>
                <meta property="og:title" content="Give some love." />
                <meta property="og:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/donate_header.webp" />
                <meta property="og:url" content="https://www.greenor.org/donate" />
                <meta property="og:description" content="Make a donate to make the planet greener and more equitable." />
                <meta name="twitter:title" content="Give some love." />
                <meta name="twitter:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/donate_header.webp" />
                <meta name="twitter:url" content="https://www.greenor.org/donate" />
                <meta name="twitter:card" content="Make a donate to make the planet greener and more equitable." />
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid>
                    <IonRow class='ion-content-justify-center ion-text-center'>
                        { showAmountComponent &&
                            <IonCol className='section'>
                                <Amount amount={donationAmount} donationAmountChanged={donationAmountChanged}
                                    giveFrequencyChanged={giveFrequencyChanged} inHonorChanged={inHonorChanged}
                                    inHonorCheckerChanged={inHonorCheckerChanged} amountConfirmed={amountConfirmed}></Amount>
                            </IonCol>
                        }
                        {showAmountComponent &&
                            <IonCol className='section'>
                                <AmountImpact amount={donationAmount}></AmountImpact>
                            </IonCol>
                        }
                        {/* showPaymentOptionsComponent &&
                            <IonCol className='section'>
                                <PaymentOptions amount={donationAmount} goBackToAmount={goBackToAmount} goToStripePayment={goToStripePayment}></PaymentOptions>
                            </IonCol>
                        */}
                        {showStripePayment &&
                            <IonCol className='section'>
                                <StripePayment amount={donationAmount} goBackToAmount={goBackToAmount} donationFrequency={frequency} inHonorOf={inHonorOf}></StripePayment>
                            </IonCol>
                        }
                    </IonRow>
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default MoneyAmount;
