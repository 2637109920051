import React, { useState } from 'react';
import { IonLabel, IonInput, IonSegment, IonSegmentButton, IonButton, IonItem, IonIcon, IonCheckbox } from '@ionic/react';
import { heart } from 'ionicons/icons';

interface AmountProps {
    amount: number;
    donationAmountChanged: (val: number) => void;
    giveFrequencyChanged: (e: any) => void;
    inHonorChanged: (e: any) => void;
    inHonorCheckerChanged: (val: boolean) => void;
    amountConfirmed: () => void;
}

const Amount: React.FC<AmountProps> = ({ amount, donationAmountChanged, giveFrequencyChanged, inHonorChanged, inHonorCheckerChanged, amountConfirmed }) => {

    const [isInHonor, setIsInHonor] = useState(false);

    const amountSegmentClicked = (e: any) => {
        const value = e.target.value;
        document.getElementById('amountInput')?.setAttribute('value', value);
    }

    const inHonorChecked = (e: any) => {
        const checker = !isInHonor;
        setIsInHonor(checker);
        inHonorCheckerChanged(checker)
    }

    const amountChanged = (e: any) => {
        const value = e.target.value;
        document.getElementById('valueSegment')?.setAttribute('value', value);
        donationAmountChanged(parseInt(value));
    }

    return (
        <>
            <IonSegment color='secondary' className='once-or-monthtly' onIonChange={giveFrequencyChanged}>
                <IonSegmentButton value="monthly">
                    <IonLabel>Monthly</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="once">
                    <IonLabel>Give once</IonLabel>
                </IonSegmentButton>
            </IonSegment>

            <IonSegment id='valueSegment' value="" color='primary' onIonChange={amountSegmentClicked}>
                <IonSegmentButton value="72">
                    <IonLabel>$72</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="144">
                    <IonLabel>$144</IonLabel>
                </IonSegmentButton>

                <IonSegmentButton value="288">
                    <IonLabel>$288</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="576">
                    <IonLabel>$576</IonLabel>
                </IonSegmentButton>
            </IonSegment>

            <IonItem class='input-item'>
                <IonLabel>$</IonLabel>
                <IonInput type='number' id='amountInput' color='primary' value={amount} onIonChange={amountChanged}></IonInput>
            </IonItem>

            <IonItem>
                <IonCheckbox slot='start' className='honor-checkbox' onIonChange={inHonorChecked}></IonCheckbox>
                <IonLabel>Give in honor or in memory</IonLabel>
            </IonItem>

            {isInHonor && <IonItem>
                <IonInput id='honorName' placeholder='Name of someone special to you' onIonChange={inHonorChanged}></IonInput>
            </IonItem>}

            <IonButton size='large' className='donate-button' onClick={amountConfirmed}>
                <IonIcon icon={heart} color='danger' slot='start'></IonIcon>
                Donate
            </IonButton>
        </>
    );
};

export default Amount;
