import { IonButtons, IonContent, IonHeader, IonCard, IonPage, IonTitle, IonToolbar, IonCardContent,
    IonImg, IonButton, IonCardHeader, IonCardTitle, IonRow, IonCol, IonThumbnail, IonProgressBar, IonIcon } from '@ionic/react';
import { cashOutline, laptopOutline, phonePortraitOutline, tabletPortraitOutline } from 'ionicons/icons';
import { NavButtons } from '../components/NavButtons';
import { Footer } from '../components/Footer';
import ImportExternalScripts from './../hooks/ImportExternalScripts';
import { Helmet } from "react-helmet";
import './Donate.css';

import Title from '../components/Title';
/*import DeviceCategoriesData from '../data/DeviceCategoriesData';
const deviceCategoriesData = new DeviceCategoriesData();
const categories = deviceCategoriesData.getCategories();*/

const Donate: React.FC = () => {

    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/analytics.js" });
    return (
        <IonPage>

            <Helmet>
                <title>Donate electronic devices to plant trees that protect the environment and help smallholder farmers.</title>
                <meta property="og:title" content="Donate electronic devices to plant trees that protect the environment and help smallholder farmers." />
                <meta property="og:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/donate_header.webp" />
                <meta property="og:url" content="https://www.greenor.org/donate" />
                <meta property="og:description" content="Donate a device for a greener and more equitable planet." />
                <meta name="twitter:title" content="Donate electronic devices to plant trees that protect the environment and help smallholder farmers." />
                <meta name="twitter:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/donate_header.webp" />
                <meta name="twitter:url" content="https://www.greenor.org/donate" />
                <meta name="twitter:card" content="Donate a device for a greener and more equitable planet." />
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonProgressBar value={0.1} color='secondary'></IonProgressBar>
                <IonHeader>
                    <IonImg src="https://greenor.s3.us-east-2.amazonaws.com/img/donate_header.webp" />
                    <IonImg src="https://greenor.s3.us-east-2.amazonaws.com/img/donate_sub_header.webp" />
                </IonHeader>
                <IonTitle>
                    <h3>Select the type of donation:</h3>
                </IonTitle>
                <IonRow>
                    <IonCol>
                        <IonCard class="ion-text-center" href='./donate/phones'>
                            <IonIcon md={phonePortraitOutline} class='ion-icon-donate' ></IonIcon>

                            <IonCardHeader>
                                <IonCardTitle>Phone</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonButton fill="outline" slot="end" href='./donate/phones' color='secondary'>Select</IonButton>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    <IonCol>
                        <IonCard class="ion-text-center" href='./donate/money'>
                            <IonIcon md={cashOutline} class='ion-icon-donate' ></IonIcon>

                            <IonCardHeader>
                                <IonCardTitle>Money</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonButton fill="outline" slot="end" href='./donate/money' color='secondary'>Select</IonButton>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    {/*categories.map((cat, idx) => {
                        return (
                        <IonCol key={idx}>
                            <IonCard class="ion-text-center" href={cat.url}>
                                <IonIcon md={phonePortraitOutline} class='ion-icon-donate' ></IonIcon>
                                {<IonThumbnail class="center">
                                        <IonImg src={cat.imageUrl} class='icon-greenish' />
                                </IonThumbnail>}
                                <IonCardHeader>
                                    <IonCardTitle>{cat.name}</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonButton fill="outline" slot="end" href={cat.url} color='secondary'>Select</IonButton>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>)
                    })*/}
                </IonRow>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default Donate;
