import { Storage } from '@ionic/storage';
import { StorageKeys } from '../models/StorageKeys';
import Device from '../models/Device';
import Address from '../models/Address';

const storage = new Storage();
storage.create().then(() => { return; });

const LocalStorageData =
{
    addDevice: (device: Device) => {
        return storage.get(StorageKeys.devices)
                    .then((data) => {
                        let devices: Array<Device> = data;
                        if (devices && typeof devices === 'object') {
                            devices.push(device)
                            return storage.set(StorageKeys.devices, devices)
                                .then(() => {
                                    return devices;
                                }).catch(e => {
                                    return null;
                                });
                        }
                        else {
                            return storage.set(StorageKeys.devices, [device])
                                .then(() => {
                                    return [device];
                                }).catch(e => {
                                    return null;
                                });
                        }
                    });
    },
    getDevices: () => {
        return storage.get(StorageKeys.devices)
            .then((data) => {
                let devices: Array<Device> = data;
                return devices;
            }).catch(e => {
                return null;
            });
    },
    addAddres: (address: Address) => {
        return storage.set(StorageKeys.address, address)
                    .then(() => {
                        return;
                    }).catch(e => {
                        return;
                    })
    },
    getAddress: () => {
        return storage.get(StorageKeys.address)
                    .then((data) => {
                        if (data && typeof data === 'object') {
                            let address: Address = data;
                            return address;
                        } else {
                            return null;
                        }
                    }).catch(e => {
                        return null;
                    })
    },
    clearAll: () => {
        return storage.clear()
                .then(() => {
                    return;
                }).catch(e => {
                    return;
                })
    },
    removeDevices: () => {
        return storage.remove(StorageKeys.devices)
            .then(() => {
                return;
            }).catch(e => {
                return;
            })
    },
    removeAddress: () => {
        return storage.remove(StorageKeys.address)
            .then(() => {
                return;
            }).catch(e => {
                return;
            })
    }
}

export default LocalStorageData;