import React from 'react';
import { IonList, IonItem, IonLabel, IonInput, IonSelectOption, IonSelect } from '@ionic/react';
import Address from '../models/Address';
import UsStates from './../data/UsStates';
import './Address.css';

interface AddressProps {
    address: Address;
    updateName: (e: any) => void;
    updateAddress1: (e: any) => void;
    updateAddress2: (e: any) => void;
    updateCity: (e: any) => void;
    onStateChange: (e: any) => void;
    updateZipCode: (e: any) => void;
}

const AddressComponent: React.FC<AddressProps> = ({ address, updateName, updateAddress1, updateAddress2, updateCity, onStateChange, updateZipCode }) => {
    return (
        <IonList>
            <IonItem>
                <IonLabel position="stacked">
                    Full Name * <span className='error-message' id='nameErrorMessage'>(A name is required)</span>:
                </IonLabel>
                <IonInput value={address.fullName} onIonBlur={updateName} type='text' placeholder='Enter your first and last name'></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">
                    Address Line 1 * <span className='error-message' id='address1ErrorMessage'>(An address is required)</span>:
                </IonLabel>
                <IonInput value={address.address1} onIonBlur={updateAddress1} type='text' placeholder='Enter your address'></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">Address Line 2:</IonLabel>
                <IonInput value={address.address2} onIonBlur={updateAddress2} type='text' placeholder=''></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">
                    City * <span className='error-message' id='cityErrorMessage'>(A city is required)</span>:
                </IonLabel>
                <IonInput value={address.city} onIonBlur={updateCity} type='text' placeholder='Enter your city'></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">
                    State * <span className='error-message' id='stateErrorMessage'>(A state is required)</span>:
                </IonLabel>
                <IonSelect onIonBlur={onStateChange} selectedText={address.state} placeholder='Select state'>
                    { UsStates.map((state) => {
                        return (<IonSelectOption value={state.abbr} key={state.abbr}>{state.name}</IonSelectOption>)
                    })}
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">
                    Zip Code * <span className='error-message' id='zipCodeErrorMessage'>(A zip code is required)</span>:
                </IonLabel>
                <IonInput value={address.zipCode} onIonBlur={updateZipCode} type='number' placeholder='Zip code'></IonInput>
            </IonItem>
        </IonList>
    );
};

export default AddressComponent;
