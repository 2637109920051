class Device {

    name?: string;
    category?: string;
    imageUrl?: string;
    manufacturerId?: string;
    manufacturerName?: string;
    memorySize?: string;
    color?: string;
    powerOn?: boolean;
    isFunctional?: boolean;
    hasCracks?: boolean;
    isDeregistered?: boolean;
    phoneCarrierName?: string;
    phoneCarrierId?: string;
    estimatedValue?: number;
}

export default Device;