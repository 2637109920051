import { API, graphqlOperation } from 'aws-amplify';
import { listGreenorDonations } from '../graphql/queries';
import { createGreenorDonation, updateGreenorDonation } from '../graphql/mutations';
import Address from '../models/Address';
import Device from '../models/Device';
import { StringGenerator } from '../utils/StringGenerator';
import { Constants } from '../utils/Constants';

const DonationData  = {

    getDonations: async () => {
        try {
            const donations = await API.graphql(graphqlOperation(listGreenorDonations, {
                limit: 20
            }));
            const rst = donations['data']['listGreenorDonations']['items'];
            return rst;
        } catch (err) {
            console.error('Error happened getting donations:', err);
            return [];
        }
    },
    createDonation: async (donorName: string, email: string, phoneNumber: string, value: number,
        numberOfTrees: number, carbonOffset: number, address: Address, devices: Array<Device>) => {
        // TODO should check whether generated ID exists already or not, if it exists, generate a different one
        const donationId = StringGenerator.gen(Constants.RECEIPT_NUMBER_LENGTH, 'ALPHA_NUMERIC');
        try {
            const created = await API.graphql(graphqlOperation(createGreenorDonation,
                {input: {
                donationId: donationId,
                donor: donorName,
                email: email,
                phoneNumber: phoneNumber,
                value: value,
                estimatedNumberOfTrees: numberOfTrees,
                estimatedCarbonOffset: carbonOffset,
                address: JSON.stringify(address),
                devices: JSON.stringify(devices),
                timestamp: new Date().getDate()

            }}));
            return created['data']['createGreenorDonation'];
        } catch(err) {
            return null;
        }
    },
    updateDonationShippingLabelUrl: async (donationId: string, url: string) => {
        try {
            const updated = await API.graphql(graphqlOperation(updateGreenorDonation, { input: {
                donationId: donationId, shippingLabelUrl: url
            }}));
            return updated['data']['updateGreenorDonation'];
        } catch(err) {
            console.error('Failed updating shipping label url: ', err);
        }
    }
 }

export default DonationData;