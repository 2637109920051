import { IonLabel, IonInput, IonItem, IonList, IonButton, IonSpinner } from '@ionic/react';
import FetchData from '../data/FetchData';
import './Donor.css';
import EmailUtil from './../utils/EmailUtil';
import { Constants } from '../utils/Constants';
import Helper from '../utils/Helper';

interface DonorProps {
    onCustomerCreated: (customerId: string, email: string, name: string) => void;
    email: string;
    name: string;
}

let customerCreated = false;

const Donor: React.FC<DonorProps> = ({ onCustomerCreated, email, name }) => {

    let donorName: string = name;
    let donorEmail: string = email;

    const validateInputs = (): boolean => {
        let valid = EmailUtil.validate(donorEmail);
        return valid;
    }

    const createDonor = () => {
        if (!validateInputs()) {
            Helper.showErrorMessage(true, 'emailErrorMessage');
            return;
        } else if (customerCreated) {
            return;
        } else {
            Helper.showErrorMessage(false, 'emailErrorMessage');
            document.getElementById('create-customer-button')?.classList.add('hide');
            document.getElementById('spinner')?.classList.remove('hide');
            customerCreated = true;
            FetchData.fetch(Constants.STRIPE_CREATE_CUSTOMER_URL, 'POST', { email: donorEmail, customerName: donorName })
                .then(resp => {
                    document.getElementById('spinner')?.classList.add('hide');
                    const customerId = resp['customerId'];
                    onCustomerCreated(customerId, donorEmail, donorName);
                }).catch(e => {
                    document.getElementById('spinner')?.classList.add('hide');
                    document.getElementById('create-customer-button')?.classList.remove('hide');
                    console.log('An error happened creating a stripe customer: ', e);
                });
        }
    }

    const onEmailUpdated = (e: any) => {
        donorEmail = e.target.value;
    }

    const onNameUpdated = (e: any) => {
        donorName = e.target.value;
    }

    return (
        <>
            <IonList id='donor-section'>
                <IonItem>
                    <IonLabel position='stacked'>Name:</IonLabel>
                    <IonInput value={donorName} onIonChange={onNameUpdated} type='text' placeholder='Enter your full name'></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position='stacked'>Email * <span className='error-message' id='emailErrorMessage'>(A valid email is needed for donation receipt)</span></IonLabel>
                    <IonInput onIonBlur={createDonor} onIonChange={onEmailUpdated} value={donorEmail} placeholder='Enter your email' type='email'></IonInput>
                </IonItem>
            </IonList>

            <div>
                <IonButton id='create-customer-button' color='light' onClick={createDonor}>Next</IonButton>
                <IonSpinner name='circular' id='spinner' className='hide'></IonSpinner>
            </div>
        </>
    );
};

export default Donor;
