import { IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonRow, IonCol, IonGrid } from '@ionic/react';
import { useEffect, useState } from 'react';

import { NavButtons } from '../components/NavButtons';
import { Footer } from '../components/Footer';
import Title from '../components/Title';
import TreesData from '../data/TreesData';
import Tree from '../models/Tree';
import TreeComponent from '../components/Tree';
import ImportExternalScripts from './../hooks/ImportExternalScripts';
import { Helmet } from "react-helmet";
import './Trees.css';

const treesData = new TreesData();

const numberOfTreesPerRow: number = 4;

const Trees: React.FC = () => {

    const [rowsOfTrees, setRowsOfTrees] = useState([] as any);

    const getRow = (trees: Array<Tree>, rowIndex:string) => {
        return <IonRow key={rowIndex}>
            {trees.map((tree, idx) => {
                return (
                    <IonCol key={idx} >
                        <TreeComponent tree={tree} />
                    </IonCol>
                )
            })}
        </IonRow>
    }

    useEffect(() => {

        treesData.getTrees()
                .then(results => {
                    const rows: any = [];
                    for (let i = 0; i < results.length; i++) {
                        if (i % numberOfTreesPerRow == 0) {
                            let row = getRow(results.slice(i, i + numberOfTreesPerRow), i.toString());
                            rows.push(row);
                        }
                    }
                    setRowsOfTrees(rows);
                });
    }, []);
    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/analytics.js" });
    return (
        <IonPage>

            <Helmet>
                <title>Fruit trees that we give to farmers.</title>
                <meta property="og:title" content="Fruit trees for Greenor Agroforestry" />
                <meta property="og:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/avocado_1.jpeg" />
                <meta property="og:url" content="https://www.greenor.org/fruit-trees" />
                <meta property="og:description" content="Our agroforestry methodologies focus on plantation of fruit trees as they have the highest impact on smallholder farmers' livelihood." />
                <meta name="twitter:title" content="Fruit trees for Greenor Agroforestry" />
                <meta name="twitter:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/avocado_1.jpeg" />
                <meta name="twitter:url" content="https://www.greenor.org/fruit-trees" />
                <meta name="twitter:card" content="Our agroforestry methodologies focus on plantation of fruit trees as they have the highest impact on smallholder farmers' livelihood." />
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className='ion-padding'>
                <IonGrid>
                    <IonRow class="ion-justify-content-center ion-text-center">
                        <h2>The fruit trees</h2>
                    </IonRow>
                    { rowsOfTrees }
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default Trees;
