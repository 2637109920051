import React from 'react';
import { Helmet } from "react-helmet";

const Header: React.FC = () => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <title>Donate electronic devices to plant trees that protect the environment and help smallholder farmers.</title>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content="Greenor, recycle and reuse electronic waste to make the planet greener by planting trees that help smallholder farmers." />
            <meta name="keywords" content="climate change, global warming, recycling, planting trees, donate trees, donate iphone, donate phone, donate smartphone, donate laptosp, electronic waste, buy used phones, buy used smartphones, buy used tablets, buy used laptop, buy used iphones" />
            <meta name="author" content="Greenor.org" />

            <meta property="og:title" content="e-Waste for a greener and more equitable planet." />
            <meta property="og:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/lone_tree.jpeg" />
            <meta property="og:url" content="https://www.greenor.org" />
            <meta property="og:site_name" content="Greenor.org" />
            <meta property="og:description" content="Greenor, recycle and reuse electronic waste to make the planet greener by planting trees that help smallholder farmers." />
            <meta name="twitter:title" content="e-Waste for a greener and more equitable planet." />
            <meta name="twitter:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/lone_tree.jpeg" />
            <meta name="twitter:url" content="https://www.greenor.org" />
            <meta name="twitter:card" content="Greenor, recycle and reuse electronic waste to make the planet greener by planting trees that help smallholder farmers." />

            <link href="https://fonts.googleapis.com/css?family=Oxygen:300,400" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700" rel="stylesheet" />

        </Helmet>
    );
};

export default Header;
