import { IonContent, IonCard, IonCardContent, IonImg, IonCardHeader, IonCardTitle, IonCardSubtitle, IonIcon, IonPopover } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import Tree from '../models/Tree';

interface TreeProps {
    tree: Tree;
}

const TreeComponent: React.FC<TreeProps> = ({ tree }) => {

    return (
        <IonCard class="ion-text-center" >
            <IonImg src={tree.images[0]} />
            <IonCardHeader>
                <IonCardTitle>{tree.name.toLocaleUpperCase()}</IonCardTitle>
                <IonCardSubtitle>{tree.species}</IonCardSubtitle>
                <IonCardSubtitle>Cost: ${tree.cost}</IonCardSubtitle>
                <IonCardSubtitle>
                    CO<sub>2</sub>: {tree.carbonOffsetKgPerYear * 10}kg
                    <IonIcon ios={informationCircleOutline} id={tree.name.toString()} />
                    <IonPopover trigger={tree.name.toString()} triggerAction='hover' alignment='center' mode='ios'>
                        <IonContent class="ion-padding">Carbon captured from the air over a 10 years period.</IonContent>
                    </IonPopover>
                </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                {tree.description}
            </IonCardContent>
        </IonCard>
    );
};

export default TreeComponent;
