import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonItemDivider, IonPage, IonRow, IonToolbar } from '@ionic/react';
import { NavButtons } from './../../components/NavButtons';
import { Footer } from './../../components/Footer';
import Title from './../../components/Title';
import { Helmet } from "react-helmet";
import ImportExternalScripts from './../../hooks/ImportExternalScripts';

const ClimateJusticeFarmers: React.FC = () => {

    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/analytics.js" });
    return (
        <IonPage>

            <Helmet>
                <title>Climate justice for smallholder farmers</title>
                <meta property="og:title" content="Climate justice for smallholder farmers" />
                <meta property="og:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/farmers-with-harvest.webp" />
                <meta property="og:url" content="https://www.greenor.org/blog/climate-justice-for-smallholder-farmers" />
                <meta property="og:description" content="Climate justice for smallholder farmers." />
                <meta name="twitter:title" content="Climate justice for farmers" />
                <meta name="twitter:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/farmers-with-harvest.webp" />
                <meta name="twitter:url" content="https://www.greenor.org/blog/climate-justice-for-smallholder-farmers" />
                <meta name="twitter:card" content="Climate justice for smallholder farmers." />
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className='ion-padding'>
                <IonGrid>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <h2>Climate justice for smallholder farmers</h2>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <p>
                                Climate justice is the principle that all people, regardless of their socioeconomic status or location, have the right to a safe and healthy environment, and that those who contribute the least to climate change should not bear a disproportionate burden of its impacts. Smallholder farmers, who make up a significant proportion of the world's poor and marginalized communities, are particularly vulnerable to the impacts of climate change, and are often the ones who suffer the most from its consequences.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow class="agroforestry">
                        <IonCol>
                            <IonImg src='https://greenor.s3.us-east-2.amazonaws.com/img/farmers-with-harvest.webp'></IonImg>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <p>
                                Smallholder farmers are the backbone of many rural communities in developing countries and are responsible for providing a significant portion of the world's food. However, they are often the most vulnerable to the impacts of climate change, such as droughts, floods, and extreme weather events; and they cause the least pollution. These events can destroy crops, reduce yields, and make it difficult for smallholder farmers to feed their families and earn a living.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <p>
                                In addition to these direct impacts on agriculture, climate change is also having indirect effects on smallholder farmers. For example, it is contributing to the spread of diseases and pests that can devastate crops and livestock, and is making it more difficult for smallholder farmers to access the resources and support they need to adapt to these changes.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <p>
                                The impacts of climate change on smallholder farmers are particularly severe in developing countries, where many communities are already struggling with poverty, malnutrition, and other challenges. These communities are often the least able to adapt to the impacts of climate change, and are most in need of support to mitigate its effects and build resilience.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <p>
                                Climate justice for smallholder farmers means taking steps to address these inequities and providing these communities with the resources and support they need to adapt to a changing climate. This could include providing access to affordable technology and infrastructure, such as irrigation systems and weather-resistant seeds. It could also involve providing education and training on sustainable farming practices, and supporting smallholder farmers to organize and advocate for their rights, which is <a href='https://greenor.org' target='blank'>Greenor.org</a>'s mission. <a href='https://greenor.org' target='blank'>Greenor.org</a> trains farmers to use agroforestry methodologies to fight the climate change impacts.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <p>
                                In addition, climate justice for smallholder farmers means recognizing the role that these communities play in addressing climate change. Smallholder farmers can adopt sustainable farming practices that reduce greenhouse gas emissions and help mitigate the impacts of climate change. For example, they can use agroecological methods that rely on natural processes and biodiversity, instead of chemical fertilizers and pesticides. This not only helps smallholder farmers adapt to a changing climate but also contributes to global efforts to combat climate change.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <p>
                                In conclusion, climate justice for smallholder farmers is about ensuring that these vulnerable communities have the resources and support they need to adapt to the impacts of climate change, and about recognizing the important role that they play in addressing this global challenge. It's about protecting the rights of smallholder farmers and supporting their efforts to continue to produce food for the world.
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default ClimateJusticeFarmers;
