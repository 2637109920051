import { API, graphqlOperation } from 'aws-amplify';
import { listGreenorDonors } from './../graphql/queries';
import { createGreenorDonor } from './../graphql/mutations';

const DonorData = {

    getDonors: async () => {
        try {
            const donors = await API.graphql(graphqlOperation(listGreenorDonors, {
                limit: 20
            }));
            const rst = donors['data']['listGreenorDonors']['items'];
            return rst;
        } catch (err) {
            return [];
        }
    },
    createDonor: async (email: any, name: any, inHonorOf: any, donationAmount: any, frequency: any) => {

        console.log(`Name: ${name}, email: ${email}, inHonor: ${inHonorOf}, amount: ${donationAmount}, frequency: ${frequency}`);
        try {
            const created = await API.graphql(graphqlOperation(createGreenorDonor, {
                input: {
                    email: email,
                    name: name,
                    timestamp: new Date().getTime().toString(),
                    inHonorOf: inHonorOf,
                    donationAmount: donationAmount,
                    frequency: frequency
                }
            }));
            return created;
        } catch (err) {
            return null;
        }
    }
}

export default DonorData;