import { useEffect } from "react";

const ImportExternalScripts = ({ url }) => {

    useEffect(() => {
        const script = document.createElement('script');
        script.setAttribute('src', url);
        //script.setAttribute('type', 'text/javascript');
        script.setAttribute('crossorigin', 'anonymous');
        script.async = false;
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        };
    }, [url]);
};

export default ImportExternalScripts;