import {
    IonButtons, IonContent, IonHeader, IonLabel, IonPage, IonTitle, IonToolbar, IonList, IonItem,
    IonButton, IonRow, IonCol, IonProgressBar, IonText, IonInput, IonSelect, IonSelectOption, useIonRouter, IonGrid
} from '@ionic/react';
import { NavButtons } from '../components/NavButtons';
import { Footer } from '../components/Footer';
import AddressComponent from '../components/Address';
import './Shipping.css';
import { Helmet } from "react-helmet";
import Address from '../models/Address';
import LocalStorageData from './../data/LocalStorageData';
import { useEffect, useState } from 'react';
import Title from '../components/Title';
import EmailUtil from '../utils/EmailUtil';
import Helper from '../utils/Helper';
import ImportExternalScripts from './../hooks/ImportExternalScripts';

const Shipping: React.FC = () => {

    const router = useIonRouter();
    const [address, setAddress] = useState(new Address());

    useEffect(() => {
        LocalStorageData.getAddress()
            .then((data) => {
                if (data && typeof data === 'object') {
                    setAddress(data);
                    return;
                }
            }).catch(e => {
                console.error('Error getting address: ', e);
                return;
            });
    }, []);

    const onStateChange = (e: any) => {
        let stateAbbr = e.target.value;
        address.state = stateAbbr;
        validateAddress();
        Helper.showErrorMessage(!validateState(stateAbbr), 'stateErrorMessage');
    }
    const validateGenericTextInput = (value: any): boolean => {
        if (!value || value.trim().length < 4) {
            return false;
        } else {
            return true;
        }
    }
    const validateState = (state: any) => {
        if (!state || (state.trim().length != 2)) {
            return false;
        } else {
            return true;
        }
    }
    const validateZipCode = (zip: any): boolean => {
        if (!zip || (zip.toString().length != 5)) {
            return false;
        } else {
            return true;
        }
    }
    const validateAddress = () => {
        if (!EmailUtil.validate(address.email)) {
            return false;
        } else if (!validateGenericTextInput(address.fullName)) {
            return false;
        } else if (!validateGenericTextInput(address.address1)) {
            return false;
        } else if (!validateGenericTextInput(address.city)) {
            return false;
        } else if (!validateState(address.state)) {
            return false;
        } else if (!validateZipCode(address.zipCode)) {
            return false;
        } else {
            return true;
        }
    }

    const updateEmail = (e: any) => {
        const value = e.target.value;
        address.email = value;
        Helper.showErrorMessage(!EmailUtil.validate(value), 'emailErrorMessage');
    }
    const updateName = (e: any) => {
        const value = e.target.value;
        address.fullName = value
        Helper.showErrorMessage(!validateGenericTextInput(value), 'nameErrorMessage');
    }
    const updateAddress1 = (e: any) => {
        const value = e.target.value;
        address.address1 = value;
        Helper.showErrorMessage(!validateGenericTextInput(value), 'address1ErrorMessage');
    }
    const updateAddress2 = (e: any) => {
        const value = e.target.value;
        address.address2 = value;
    }
    const updateCity = (e: any) => {
        const value = e.target.value;
        address.city = value;
        Helper.showErrorMessage(!validateGenericTextInput(value), 'cityErrorMessage');
    }
    const updateZipCode = (e: any) => {
        const value = e.target.value;
        address.zipCode = value;
        Helper.showErrorMessage(!validateZipCode(value), 'zipCodeErrorMessage');
    }
    const updatePhone = (e: any) => {
        const value = e.target.value;
        address.phone = value;
    }
    const goBack = (e: any) => {
        e.preventDefault();
        if (router.canGoBack()) {
            router.goBack();
        }
    }
    const finishClicked = (e: any) => {
        e.preventDefault();
        if (validateAddress()) {
            LocalStorageData.addAddres(address);
            let nextUrl = '/donate/summary';
            router.push(nextUrl);
        } else {
            Helper.showErrorMessage(!EmailUtil.validate(address.email), 'emailErrorMessage');
            Helper.showErrorMessage(!validateGenericTextInput(address.fullName), 'nameErrorMessage');
            Helper.showErrorMessage(!validateGenericTextInput(address.address1), 'address1ErrorMessage');
            Helper.showErrorMessage(!validateGenericTextInput(address.city), 'cityErrorMessage');
            Helper.showErrorMessage(!validateState(address.state), 'stateErrorMessage');
            Helper.showErrorMessage(!validateZipCode(address.zipCode), 'zipCodeErrorMessage');
        }
    }
    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/analytics.js" });
    return (
        <IonPage>

            <Helmet>
                <title>Shipping information for a free shipping label.</title>
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonProgressBar value={0.95} color='secondary'></IonProgressBar>
                <IonTitle>
                    <h3>Shipping information</h3>
                    <IonText>We use this information to create your free shipping label.</IonText>
                </IonTitle>

                <form>
                    <AddressComponent address={address} updateName={updateName} updateAddress1={updateAddress1} updateAddress2={updateAddress2} updateCity={updateCity} onStateChange={onStateChange} updateZipCode={updateZipCode}></AddressComponent>
                    <IonList>
                        <IonItem>
                            <IonLabel position="stacked">
                                Email * <span className='error-message' id='emailErrorMessage'>(A valid email is required)</span>:
                            </IonLabel>
                            <IonInput value={address.email} onIonBlur={updateEmail} type='email' placeholder='Enter your email'></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Phone :</IonLabel>
                            <IonInput value={address.phone} onIonBlur={updatePhone} type='tel' placeholder=''></IonInput>
                        </IonItem>
                    </IonList>
                    <IonGrid>
                        <IonRow class="ion-justify-content-center ion-align-items-center">
                            <IonCol size='4' class="ion-justify-content-center">
                                <IonButton id='addDevice' onClick={goBack} color="light" href='#' size='large'>Back</IonButton>
                            </IonCol>
                            <IonCol size='4' class="ion-justify-content-center">
                                <IonButton onClick={finishClicked} size='large' href='#' color='secondary'>Finish</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </form>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default Shipping;
