import DeviceModel from '../models/DeviceModel';
import { API, graphqlOperation } from 'aws-amplify';
import { getGreenorDeviceModel, queryGreenorDeviceModelsByCategoryIndex } from './../graphql/queries';
import { createGreenorDeviceModel, updateGreenorDeviceModel } from './../graphql/mutations';

const models: DeviceModel[] = [
    {
        name: 'iPhone 12 Pro Max',
        category: 'Phones',
        imageUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/apple_iphone_12_pro_max.jpeg',
        manufacturerName: 'Apple',
        availableColors: ['Graphite', 'Gold', 'Silver', 'Pacific Blue'],
        availableMemorySizes: ['128GB', '256GB', '512GB'],
        valueEstimatesByMemory: new Map([['128GB', { lower: 60, middle: 170, upper: 320 }],
        ['256GB', { lower: 80, middle: 190, upper: 340 }], ['512GB', { lower: 80, middle: 190, upper: 360 }]]),
        smallestAvailableMemory: '128GB',
        largestAvailableMemory: '512GB'
    },
    {
        name: 'iPhone 12 Pro',
        category: 'Phones',
        imageUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/apple_iphone_12_pro_max.jpeg',
        manufacturerName: 'Apple',
        availableColors: ['Graphite', 'Gold', 'Silver', 'Pacific Blue'],
        availableMemorySizes: ['128GB', '256GB', '512GB'],
        valueEstimatesByMemory: new Map([['128GB', { lower: 60, middle: 170, upper: 320 }],
        ['256GB', { lower: 80, middle: 190, upper: 360 }], ['512GB', { lower: 80, middle: 190, upper: 360 }]]),
        smallestAvailableMemory: '128GB',
        largestAvailableMemory: '512GB'
    },
    {
        name: 'iPhone 12',
        category: 'Phones',
        imageUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/apple_iphone_12.jpeg',
        manufacturerName: 'Apple',
        availableColors: ['Blue', 'Green', 'Black', 'Red', 'White', 'Purple'],
        availableMemorySizes: ['64GB', '128GB', '256GB'],
        valueEstimatesByMemory: new Map([['64GB', { lower: 60, middle: 170, upper: 320 }],
            ['128GB', { lower: 80, middle: 180, upper: 360 }], ['256GB', { lower: 80, middle: 190, upper: 380 }]]),
        smallestAvailableMemory: '64GB',
        largestAvailableMemory: '256GB'
    },
    {
        name: 'iPhone 12 Mini',
        category: 'Phones',
        imageUrl: 'https://greenor.s3.us-east-2.amazonaws.com/img/apple_iphone_12.jpeg',
        manufacturerName: 'Apple',
        availableColors: ['Blue', 'Green', 'Black', 'Red', 'White', 'Purple'],
        availableMemorySizes: ['64GB', '128GB', '256GB'],
        valueEstimatesByMemory: new Map([['64GB', { lower: 60, middle: 170, upper: 320 }],
            ['128GB', { lower: 80, middle: 190, upper: 360 }], ['256GB', { lower: 80, middle: 190, upper: 360 }]]),
        smallestAvailableMemory: '128GB',
        largestAvailableMemory: '512GB'
    }];

class DeviceModelsData {

    getModelsByCategory = (type: string) => {
        return models.filter(model => {
            return model.category.toLowerCase() == type.toLowerCase();
        });
    }

    getModelByNameTest = (name: string) => {
        return models.find(model => {
            return model.name.toLowerCase() == name.toLowerCase();
        });
    }

    getModelsByManufacturerAndCategory = async (manufacturer: string, category: string) => {
        const results = await API.graphql(graphqlOperation(queryGreenorDeviceModelsByCategoryIndex,
            { manufacturerName: manufacturer }));
        const models = results['data']['queryGreenorDeviceModelsByCategoryIndex']['items'];
        return models.filter(model => {
            return model.category == category;
        });
    }

    getModelByNameAndManufacturer = async (name: string, manufacturer: string) => {
        const result = await API.graphql(graphqlOperation(getGreenorDeviceModel,
            { name: name, manufacturerName: manufacturer }));
        const data = result['data']['getGreenorDeviceModel'];
        return data;
    }

    createModel = async (model: any) => {
        try {
            const created = await API.graphql(graphqlOperation(createGreenorDeviceModel, {
                input: {
                    availableColors: model.availableColors,
                    availableMemorySizes: model.availableMemorySizes,
                    category: model.category,
                    imageUrl: model.imageUrl,
                    manufacturerName: model.manufacturerName,
                    name: model.name,
                    valueEstimatesByMemory: model.valueEstimatesByMemory
                }
            }));
            return created;
        } catch (err) {
            return null;
        }
    }
    updateModel = async (model: any) => {
        try {
            const created = await API.graphql(graphqlOperation(updateGreenorDeviceModel, {
                input: {
                    availableColors: model.availableColors,
                    availableMemorySizes: model.availableMemorySizes,
                    category: model.category,
                    imageUrl: model.imageUrl,
                    manufacturerName: model.manufacturerName,
                    name: model.name,
                    valueEstimatesByMemory: model.valueEstimatesByMemory
                }
            }));
            return created;
        } catch (err) {
            return null;
        }
    }
}

export default DeviceModelsData;