import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonItemDivider, IonPage, IonRow, IonToolbar } from '@ionic/react';
import { NavButtons } from '../components/NavButtons';
import { Footer } from '../components/Footer';
import Title from '../components/Title';
import './Agroforestry.css';
import { Helmet } from "react-helmet";
import ImportExternalScripts from './../hooks/ImportExternalScripts';

const Agroforestry: React.FC = () => {

    const benefitsToFarmerImg = <IonCol>
        <IonImg src='https://greenor.s3.us-east-2.amazonaws.com/img/matunda-iko-mbele.jpeg' />
    </IonCol>;

    const benefitsToFarmerCol = <IonCol>
        <h5>Benefits to the farmers</h5>
        <IonItemDivider></IonItemDivider>
        <div>
            Fruit trees alleviate malnutrition in farmers' children.
        </div>
        <IonItemDivider></IonItemDivider>
        <div>
            Land productivity increase.
        </div>
        <IonItemDivider></IonItemDivider>
        <div>
            Increase and diversification of farmers' income.
        </div>
    </IonCol>;

    const benefitToEnvImg = <IonCol>
        <IonImg src='https://greenor.s3.us-east-2.amazonaws.com/img/famer-harvesting.webp' />
    </IonCol>;

    const benefitsToEnvCol = <IonCol>
        <h5>Environmental benefits</h5>
        <IonItemDivider></IonItemDivider>
        <div>
            Long-term carbon uptake.
        </div>
        <IonItemDivider></IonItemDivider>
        <div>
            Conservation of biodiversity.
        </div>
        <IonItemDivider></IonItemDivider>
        <div>
            Improvements in air and water quality.
        </div>
        <IonItemDivider></IonItemDivider>
        <div>
            The enrichment of soil fertility and its maintenance in the long term.
        </div>
        <IonItemDivider></IonItemDivider>
        <div>
            Deforestation decrease in nearby areas.
        </div>
    </IonCol>

    const agroDesc1Col = <IonCol>
        When planted alongside crops, most varieties of trees contribute significantly to soil health by controlling erosion,
        improving the soil’s water retention ability, and maintaining a high level of compost, as well as fixing nitrogen.
        Trees can also play an important role in improving biodiversity.
    </IonCol>;

    const agroDesc2Col = <IonCol>
        Creating an agroforestry system means cultivating a heterogeneous combination of trees,
        shrubs and seasonal crops, sometimes alongside livestock farming.
    </IonCol>;
    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/analytics.js" });
    return (
        <IonPage>

            <Helmet>
                <title>Agroforestry</title>
                <meta property="og:title" content="Agroforestry methodologies" />
                <meta property="og:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/farmers-with-harvest.webp" />
                <meta property="og:url" content="https://www.greenor.org/agroforestry" />
                <meta property="og:description" content="Agroforestry is a practical and low-cost means of implementing many forms of sustainable natural resource management, promoting long-term, sustainable and renewable forest management, especially for smallholder farmers." />
                <meta name="twitter:title" content="Agroforestry methodologies" />
                <meta name="twitter:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/farmers-with-harvest.webp" />
                <meta name="twitter:url" content="https://www.greenor.org/agroforestry" />
                <meta name="twitter:card" content="Agroforestry is a practical and low-cost means of implementing many forms of sustainable natural resource management, promoting long-term, sustainable and renewable forest management, especially for smallholder farmers." />
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className='ion-padding'>
                <IonGrid>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <h2>Trees & Agroforestry</h2>
                        </IonCol>
                    </IonRow>
                    <IonRow class="agroforestry">
                        <IonCol>
                            <IonImg src='https://greenor.s3.us-east-2.amazonaws.com/img/farmers-with-harvest.webp'></IonImg>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            Smallholder farmers are highly vulnerable to our changing climate.
                            Because their livelihoods are reliant on land, they are disproportionately affected by issues like water scarcity,
                            nutrient-depleted soil, deforestation, and reductions in biodiversity.
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            Agroforestry is the integration of tree cultivation within agricultural environments.
                            It is a practical and low-cost means of implementing many forms of sustainable natural resource management,
                            promoting long-term, sustainable and renewable forest management, especially for smallholder farmers.
                        </IonCol>
                    </IonRow>
                    <IonRow class='show-on-mobile agroforestry'>
                        {agroDesc1Col}
                    </IonRow>
                    <IonRow class='show-on-mobile agroforestry'>
                        {agroDesc2Col}
                    </IonRow>
                    <IonRow class='show-on-desktop agroforestry'>
                        {agroDesc1Col}
                        {agroDesc2Col}
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center show-on-desktop agroforestry'>
                        {benefitsToFarmerImg}
                        {benefitsToFarmerCol}
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center show-on-desktop agroforestry'>
                        {benefitsToEnvCol}
                        {benefitToEnvImg}
                    </IonRow>
                    <IonRow class='show-on-mobile agroforestry'>
                        <IonCol>{benefitsToFarmerImg}</IonCol>
                    </IonRow>
                    <IonRow class='show-on-mobile agroforestry'>
                        <IonCol>{benefitsToFarmerCol}</IonCol>
                    </IonRow>
                    <IonRow class='show-on-mobile agroforestry'>
                        <IonCol>{benefitToEnvImg}</IonCol>
                    </IonRow>
                    <IonRow class='show-on-mobile agroforestry'>
                        <IonCol>{benefitsToEnvCol}</IonCol>
                    </IonRow>
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default Agroforestry;
