import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonToolbar } from '@ionic/react';
import { leafOutline } from 'ionicons/icons';
import { useLocation } from 'react-router';
import { NavButtons } from '../components/NavButtons';
import { Footer } from '../components/Footer';
import Title from '../components/Title';
import DonorData from '../data/DonorData';
import './ThankYou.css';
import { Helmet } from "react-helmet";

import ImportExternalScripts from './../hooks/ImportExternalScripts';
import { useEffect } from 'react';

const ThankYou: React.FC = () => {

    const { search } = useLocation();
    let params = new URLSearchParams(search);
    let email = params.get('email');
    let name = params.get('nam');
    let amount = params.get('amt');
    let frequency = params.get('freq');
    let inHonorOf = params.get('hon');
    let status = params.get('redirect_status');

    useEffect(() => {
        // TODO call the backend to create a donor in the DB
        DonorData.createDonor(email, name, inHonorOf, amount, frequency)
            .then(resp => {
                console.log('Donor created in the DB..', resp);
            });
    }, []);

    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/analytics.js" });
    return (
        <IonPage>

            <Helmet>
                <title>Thank you</title>
                <meta property="og:title" content="Thank you." />
                <meta property="og:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/agroforestry.webp" />
                <meta property="og:url" content="https://www.greenor.org/faq" />
                <meta property="og:description" content="Thank you for your donation." />
                <meta name="twitter:title" content="Thank you." />
                <meta name="twitter:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/agroforestry.webp" />
                <meta name="twitter:url" content="https://www.greenor.org/faq" />
                <meta name="twitter:card" content="Thank you for your donation." />
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className='ion-padding'>
                <IonGrid>
                    <IonRow class='ion-content-justify-center ion-text-center'>
                        {status == 'succeeded' &&
                            <IonCol>
                                <IonIcon icon={leafOutline} id='thanks-leaf'></IonIcon>
                                <h3>Thank you for your donation.</h3>
                                <p>Your donation receipt will be sent to: {email}</p>
                            </IonCol>
                        }
                        {status != 'succeeded' &&
                            <IonCol>
                                <h3>The payment failed. Please <a href='https://www.greenor.org/donate/money'>try again</a>.</h3>
                            </IonCol>
                        }
                    </IonRow>
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default ThankYou;
