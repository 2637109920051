import { IonImg } from '@ionic/react';
import React from 'react';
import './Title.css';

const Title: React.FC = () => {
    return (
        <div className='greenor-title'>
            <a href='https://greenor.org/'><img id='title-logo' src='https://greenor.s3.us-east-2.amazonaws.com/img/greenor_org_cropped.png' /></a>
        </div>
    );
};

export default Title;
