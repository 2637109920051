/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGreenorManufacturer = /* GraphQL */ `
  query GetGreenorManufacturer($name: String!) {
    getGreenorManufacturer(name: $name) {
      name
      logoUrl
      isActive
    }
  }
`;
export const listGreenorManufacturers = /* GraphQL */ `
  query ListGreenorManufacturers(
    $filter: TableGreenorManufacturerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGreenorManufacturers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        logoUrl
        isActive
      }
      nextToken
    }
  }
`;
export const getGreenorDeviceModel = /* GraphQL */ `
  query GetGreenorDeviceModel($manufacturerName: String!, $name: String!) {
    getGreenorDeviceModel(manufacturerName: $manufacturerName, name: $name) {
      manufacturerName
      name
      category
      imageUrl
      availableColors
      availableMemorySizes
      valueEstimatesByMemoryUnlocked
      valueEstimatesByMemoryLocked
      valueEstimatesByMemory
    }
  }
`;
export const listGreenorDeviceModels = /* GraphQL */ `
  query ListGreenorDeviceModels(
    $filter: TableGreenorDeviceModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGreenorDeviceModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        manufacturerName
        name
        category
        imageUrl
        availableColors
        availableMemorySizes
        valueEstimatesByMemoryUnlocked
        valueEstimatesByMemoryLocked
        valueEstimatesByMemory
      }
      nextToken
    }
  }
`;
export const queryGreenorDeviceModelsByCategoryIndex = /* GraphQL */ `
  query QueryGreenorDeviceModelsByCategoryIndex(
    $manufacturerName: String!
    $first: Int
    $after: String
  ) {
    queryGreenorDeviceModelsByCategoryIndex(
      manufacturerName: $manufacturerName
      first: $first
      after: $after
    ) {
      items {
        manufacturerName
        name
        category
        imageUrl
        availableColors
        availableMemorySizes
        valueEstimatesByMemoryUnlocked
        valueEstimatesByMemoryLocked
        valueEstimatesByMemory
      }
      nextToken
    }
  }
`;
export const queryGreenorDeviceModelsByNameIndex = /* GraphQL */ `
  query QueryGreenorDeviceModelsByNameIndex(
    $name: String!
    $first: Int
    $after: String
  ) {
    queryGreenorDeviceModelsByNameIndex(
      name: $name
      first: $first
      after: $after
    ) {
      items {
        manufacturerName
        name
        category
        imageUrl
        availableColors
        availableMemorySizes
        valueEstimatesByMemoryUnlocked
        valueEstimatesByMemoryLocked
        valueEstimatesByMemory
      }
      nextToken
    }
  }
`;
export const getGreenorContactUs = /* GraphQL */ `
  query GetGreenorContactUs($email: String!, $timestamp: String!) {
    getGreenorContactUs(email: $email, timestamp: $timestamp) {
      email
      timestamp
      name
      subject
      message
      phoneNumber
    }
  }
`;
export const listGreenorContactuses = /* GraphQL */ `
  query ListGreenorContactuses(
    $filter: TableGreenorContactUsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGreenorContactuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        timestamp
        name
        subject
        message
        phoneNumber
      }
      nextToken
    }
  }
`;
export const getTrees = /* GraphQL */ `
  query GetTrees($name: String!) {
    getTrees(name: $name) {
      name
      species
      description
      cost
      carbonOffsetKgPerYear
      foodSecurityScore
      economicDevelopmentScore
      carbonScore
      environmentProtectionScore
      images
    }
  }
`;
export const listTrees = /* GraphQL */ `
  query ListTrees(
    $filter: TableTreesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        species
        description
        cost
        carbonOffsetKgPerYear
        foodSecurityScore
        economicDevelopmentScore
        carbonScore
        environmentProtectionScore
        images
      }
      nextToken
    }
  }
`;
export const getGreenorDonation = /* GraphQL */ `
  query GetGreenorDonation($donationId: String!) {
    getGreenorDonation(donationId: $donationId) {
      donationId
      timestamp
      donor
      email
      phoneNumber
      value
      estimatedNumberOfTrees
      estimatedCarbonOffset
      address
      devices
      shippingLabelUrl
    }
  }
`;
export const listGreenorDonations = /* GraphQL */ `
  query ListGreenorDonations(
    $filter: TableGreenorDonationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGreenorDonations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        donationId
        timestamp
        donor
        email
        phoneNumber
        value
        estimatedNumberOfTrees
        estimatedCarbonOffset
        address
        devices
        shippingLabelUrl
      }
      nextToken
    }
  }
`;
export const getGreenorShipment = /* GraphQL */ `
  query GetGreenorShipment($donationId: String!) {
    getGreenorShipment(donationId: $donationId) {
      donationId
      status
      labelUrl
      trackingNumber
      trackingUrl
      ETA
      isTest
      rate
      rateAmount
      provider
      currency
      objectState
      wholeObject
    }
  }
`;
export const listGreenorShipments = /* GraphQL */ `
  query ListGreenorShipments(
    $filter: TableGreenorShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGreenorShipments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        donationId
        status
        labelUrl
        trackingNumber
        trackingUrl
        ETA
        isTest
        rate
        rateAmount
        provider
        currency
        objectState
        wholeObject
      }
      nextToken
    }
  }
`;
export const getGreenorDonor = /* GraphQL */ `
  query GetGreenorDonor($email: String!) {
    getGreenorDonor(email: $email) {
      email
      name
      inHonorOf
      donationAmount
      timestamp
      frequency
    }
  }
`;
export const listGreenorDonors = /* GraphQL */ `
  query ListGreenorDonors(
    $filter: TableGreenorDonorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGreenorDonors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        email
        name
        inHonorOf
        donationAmount
        timestamp
        frequency
      }
      nextToken
    }
  }
`;
