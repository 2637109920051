import {
    IonButtons, IonContent, IonHeader, IonPage, useIonToast, IonToolbar, IonButton, IonRow, IonCol,
    IonProgressBar, useIonRouter, IonText, IonGrid, IonItemDivider, IonList, IonItem, IonImg } from '@ionic/react';

import { useEffect, useState } from 'react';
import { NavButtons } from './../components/NavButtons';
import { Footer } from './../components/Footer';
import './DeviceDetails.css';
import { Helmet } from "react-helmet";
import Device from '../models/Device';
import LocalStorageData from './../data/LocalStorageData';
import Address from '../models/Address';
import DeviceValueImpact from '../components/DeviceValueImpact';
import Offset from '../utils/Offset';
import Title from '../components/Title';
import DonationData from './../data/DonationData';
import FetchData from '../data/FetchData';
import ShippingData from './../data/ShipmentData';
import './DonationSummary.css';
import { Constants } from '../utils/Constants';
import ImportExternalScripts from './../hooks/ImportExternalScripts';

const shippingLabelImgElement = document.createElement('img');

const DonateSummary: React.FC = () => {

    let saveDonationCalled = false;
    let donation: any = {};
    const [address, setAddress] = useState(new Address());
    const [devices, setDevices] = useState(new Array<Device>());
    const [totalValue, setTotalValue] = useState(0);
    let estimatedNumberOfTrees = Offset.getEstimatedNumberOfTrees(totalValue);
    let estimatedCarbonOffset = Offset.getEstimiatedCarbonOffset(estimatedNumberOfTrees);
    const [donationId, setDonationId] = useState('');
    const [shippingLabelUrl, setShippinglabelUrl] = useState(Constants.SHIPPING_LABEL_PLACEHOLDER_URL);
    const [presentToast] = useIonToast();

    const setLabelImgSrc = (url: string) => {
        shippingLabelImgElement.setAttribute('src', url);
    }

    const showErrorMessage = (message: string) => {
        presentToast({
            header: 'Error',
            message: message,
            duration: 5000,
            position: 'bottom',
            cssClass: 'custom-toast',
            buttons: [{ text: 'Dismiss', role: 'cancel' }],
            color: 'danger'
        });
    }
    const saveDonation = () => {
        const donorName = donation.fullName ? donation.fullName : '';
        const email = donation.email ? donation.email : '';
        const phoneNumber = donation.phoneNumber ? donation.phoneNumber : '';
        DonationData.createDonation(donorName, email, phoneNumber, donation.value, donation.estimatedNumberOfTrees,
            donation.estimatedCarbonOffset, donation.address, donation.devices)
            .then((donationResult) => {

                setDonationId(donationResult['donationId']);
                FetchData.fetch(Constants.SHIPPING_LABEL_API_URL, 'POST',
                            {
                                name: donation.fullName,
                                street1: donation.address1,
                                city: donation.city,
                                state: donation.state,
                                zip: donation.zip,
                                country: Constants.SHIPPING_DEFAULT_COUNTRY
                            })
                        .then((transactionResult) => {

                            setShippinglabelUrl(transactionResult['label_url']);
                            setLabelImgSrc(transactionResult['label_url']);
                            DonationData.updateDonationShippingLabelUrl(donationResult['donationId'], transactionResult['label_url'])
                                            .then(updatedLabel => {
                                                return;
                                            });
                            ShippingData.createShipment(donationResult['donationId'], transactionResult)
                                            .then(shipmentResult => {
                                                return;
                                            });
                        }).catch((err) => {
                            showErrorMessage('Failed to generate a shipping label. Please start over.');
                        });
            }).catch(err => {
                showErrorMessage('An error happened. Please start over.');
            });
    }

    useEffect(() => {
        LocalStorageData.getAddress()
            .then((data) => {
                if (data && typeof data === 'object') {
                    setAddress(data);
                    donation.fullName = data.fullName;
                    donation.email = data.email;
                    donation.phoneNumber = data.phone;
                    donation.address1 = data.address1;
                    donation.city = data.city;
                    donation.state = data.state;
                    donation.zip = data.zipCode
                    donation.address = data;
                    if (!saveDonationCalled && donation.devices) {
                        saveDonationCalled = true;
                        saveDonation();
                    }
                    return;
                }
            }).catch(e => {
                console.error('Error getting address: ', e);
                return;
            });
        LocalStorageData.getDevices()
            .then((data) => {
                if (data && typeof data === 'object') {
                    setDevices(data);
                    const estimates = data.flatMap(d => { return d.estimatedValue ? d.estimatedValue : 0});
                    if (estimates.length > 0) {
                        const sum = estimates.reduce((a, b) => { return Number.parseInt(a.toLocaleString()) + Number.parseInt(b.toLocaleString()); });
                        setTotalValue(sum);
                        donation.value = sum;
                        donation.devices = data;
                        donation.estimatedNumberOfTrees = Offset.getEstimatedNumberOfTrees(sum);
                        donation.estimatedCarbonOffset = Offset.getEstimiatedCarbonOffset(donation.estimatedNumberOfTrees);
                        if (!saveDonationCalled && donation.fullName && donation.email && donation.address1) {
                            saveDonationCalled = true;
                            saveDonation();
                        }
                    }
                    return;
                } else {
                    console.error('No devices in the local storage.');
                    return;
                }
            }).catch(e => {
                console.error('Error getting devices: ', e);
                return;
            });
        window.onbeforeunload = () => {
            // Clear all localstorage.
            LocalStorageData.clearAll();
        }
    }, []);
    const router = useIonRouter();

    const done = (e: any) => {
        e.preventDefault();
        // Clear all localstorage.
        LocalStorageData.clearAll();
        let nextUrl = '/donate';
        router.push(nextUrl, 'forward', 'push');
    }

    const print = (e: any) => {
        e.preventDefault();
        const iframe = document.createElement('iframe');
        iframe.style.height = '0';
        iframe.style.visibility = 'hidden';
        iframe.style.width = '0';
        iframe.setAttribute('srcdoc', '<html><body></body></html>');
        document.body.appendChild(iframe);
        const donationSlip = document.createElement('div');
        const donationSlipContent = '<p>Receipt number: <span style="font-size: xx-large; font-weight: bold;">' + donationId + '</span></p>';
        donationSlip.innerHTML = donationSlipContent;

        iframe.addEventListener('load', function () {

            if (shippingLabelImgElement) {
                shippingLabelImgElement.style.maxWidth = '80%';
                const body = iframe?.contentDocument?.body;
                if (body) {
                    body.style.textAlign = 'center';
                    body.appendChild(shippingLabelImgElement);
                    body.appendChild(donationSlip);
                } else {
                    console.error('iframe body is: ', body);
                }
                shippingLabelImgElement?.addEventListener('load', function () {
                    iframe?.contentWindow?.print();
                });
            } else {
                console.error('image is: ', shippingLabelImgElement);
            }
        });
        iframe?.contentWindow?.addEventListener('afterprint', function () {
            iframe?.parentNode?.removeChild(iframe);
        });
    }

    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/googleAds.js" });
    return (
        <IonPage>

            <Helmet>
                <title>Donation summary. Thank you.</title>
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonProgressBar value={1} color='secondary'></IonProgressBar>
                <h1 className='ion-text-center'>Donation summary</h1>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <DeviceValueImpact estimatedNumberOfTrees={estimatedNumberOfTrees} estimatedCarbonOffset={estimatedCarbonOffset} />
                        </IonCol>
                    </IonRow>
                    <IonItemDivider></IonItemDivider>
                    <IonRow class="ion-justify-content-center ion-align-items-center ion-text-center">
                        <IonList class='ion-text-right'>
                            {devices.map((device, idx) => {
                                return (
                                    <IonItem key={idx}>
                                        {device.name} {device.memorySize}GB  value:  ${device.estimatedValue}
                                    </IonItem>
                                )
                            })}
                            <IonItem class='ion-float-right'>
                                <IonText>
                                    Total donation value in dollars:  <b> ${totalValue} </b>
                                </IonText>
                            </IonItem>
                        </IonList>
                    </IonRow>
                    <IonRow class="ion-justify-content-center ion-align-items-center ion-text-center">
                        <IonText>
                            <p>A tax deduction donation receipt of ${totalValue} will been sent to {address.email} when the donation is received.</p>
                        </IonText>
                    </IonRow>
                    <IonRow class="ion-justify-content-center">
                        <IonText>
                            Receipt number: {donationId}
                        </IonText>
                    </IonRow>
                    <IonItemDivider></IonItemDivider>
                    <IonRow class="ion-justify-content-center ion-align-items-center ion-text-center">
                        <h5>Free shipping label</h5>
                    </IonRow>
                    <IonRow class="ion-justify-content-center ion-align-items-center ion-text-center">
                        <p>The shipping label has been sent to {address.email}</p>
                    </IonRow>
                    <IonRow class="ion-justify-content-center ion-align-items-center ion-text-center">
                        <IonCol size='4' class="ion-justify-content-center">
                            <IonButton id='print' onClick={print} color="primary" href='#' size='small'>Print</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow class="ion-justify-content-center ion-align-items-center ion-text-center">
                        <IonImg id='shippingLabel' src={shippingLabelUrl}></IonImg>
                    </IonRow>
                    <IonItemDivider></IonItemDivider>
                    <IonRow class="ion-justify-content-center ion-align-items-center ion-text-center">
                        <IonCol size='4' class="ion-justify-content-center">
                            <IonButton id='nextBtn' onClick={done} color="secondary" href='#' size='large'>Done</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default DonateSummary;
