import { IonButton, IonMenuButton } from "@ionic/react";
import React, { useEffect } from "react";
import './NavButtons.css';

export const NavButtons = () => {
    const [mQuery, setMQuery] = React.useState<any>({
        matches: window.innerWidth > 768 ? true : false,
    });

    useEffect(() => {
        let mediaQuery = window.matchMedia("(min-width: 768px)");
        //mediaQuery.addListener(setMQuery);
        mediaQuery.onchange = () => {
            setMQuery({
                matches: window.innerWidth > 768 ? true : false,
            });
        };

        return () => mediaQuery.removeListener(setMQuery);
    }, []);

    // MediaQueryListEvent { isTrusted: true, media: "(min-width: 768px)", matches: true ...}
    //console.log(mQuery.matches);

    return (
        <div>
            {mQuery && !mQuery.matches ? (
                <IonMenuButton />
            ) : (
                <>
                    <IonButton className="menu-item" routerLink={"/"}>Home </IonButton>
                    <IonButton className="menu-item" routerLink={"/how-it-works"}>How it works </IonButton>
                    <IonButton className="menu-item" routerLink={"/donate"}>Donate</IonButton>
                    <IonButton className="menu-item" routerLink={"/faq"}>FAQ</IonButton>
                    <IonButton className="menu-item" routerLink={"/contact"}>Contact</IonButton>
                </>
            )}
        </div>
    );
};