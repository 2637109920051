import { IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonImg, IonButton, IonRow, IonCol,
    useIonToast, IonProgressBar, useIonRouter } from '@ionic/react';

import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { NavButtons } from './../components/NavButtons';
import { Footer } from './../components/Footer';
import './DeviceDetails.css';
import { Helmet } from "react-helmet";
import DeviceModelsData from '../data/DeviceModelsData';
import PhoneCarriersData from '../data/PhoneCarriersData';
import Device from '../models/Device';
import DonateBreadcrumb from '../components/DonateBreadcrumb';
import DeviceValueImpact from '../components/DeviceValueImpact';
import SelectDeviceSize from '../components/SelectDeviceSize';
import SelectDeviceCarrier from '../components/SelectDeviceCarrier';
import DeviceCondition from '../components/DeviceCondition';
import LocalStorageData from './../data/LocalStorageData';
import Offset from './../utils/Offset';
import Title from '../components/Title';
import DeviceModel from '../models/DeviceModel';
import ImportExternalScripts from './../hooks/ImportExternalScripts';

let device = new Device();
const phoneCarriersData = new PhoneCarriersData();
const carriers = phoneCarriersData.getCarriers();
const deviceModelsData = new DeviceModelsData();

const SelectModel: React.FC = () => {

    const router = useIonRouter();
    let { name } = useParams<{ name: string; }>();
    name = name.replace(/-/g, ' ');
    device.name = name;
    const [model, setModel] = useState({} as DeviceModel);
    let { manufacturerName } = useParams<{ manufacturerName: string; }>();
    manufacturerName = manufacturerName.replace(/-/g, ' ');
    device.manufacturerName = manufacturerName;
    let { deviceCategory } = useParams<{ deviceCategory: string; }>();
    deviceCategory = deviceCategory.replace(/-/, ' ');
    device.category = deviceCategory;
    const [ presentToast ] = useIonToast();
    const [ disabled, setDisabled ] = useState(true);
    const [ estimatedValue, setEstimatedValue ] = useState(0);
    let estimatedNumberOfTrees = Offset.getEstimatedNumberOfTrees(estimatedValue);
    let estimatedCarbonOffset = Offset.getEstimiatedCarbonOffset(estimatedNumberOfTrees);
    useEffect(() => {
        deviceModelsData.getModelByNameAndManufacturer(name.toLocaleLowerCase(), manufacturerName.toLowerCase())
            .then(rst => {
                rst.valueEstimatesByMemory = JSON.parse(rst.valueEstimatesByMemory);
                rst.largestAvailableMemory = Math.max(...rst.availableMemorySizes);
                rst.smallestAvailableMemory = Math.min(...rst.availableMemorySizes);
                const maxValue = rst?.valueEstimatesByMemory[rst.largestAvailableMemory.toString()]['upper'];
                setEstimatedValue(maxValue);
                setModel(rst);
            });
    }, [manufacturerName]);

    const getDeviceEstimateValue = (device: Device) => {

        let memory = typeof device.memorySize == 'string' ? device.memorySize : model?.largestAvailableMemory.toString();
        memory = typeof memory === 'string' ? memory : '';
        let valueEstimates = model?.valueEstimatesByMemory[memory];
        const carrier = device.phoneCarrierName?.toLowerCase();
        if (carrier != 'unlocked' && carrier != undefined) {
            valueEstimates = model?.valueEstimatesByMemory[memory];
        }
        if (device.powerOn == false || device.isFunctional == false) {
            return valueEstimates?.lower;
        }
        else if (device.hasCracks == true) {
            return valueEstimates?.middle;
        } else {
            return valueEstimates?.upper;
        }
    }
    const updateEstimates = (device: any) => {
        let estimatedValue = getDeviceEstimateValue(device);
        setEstimatedValue(estimatedValue ? estimatedValue : 0);
        device.estimatedValue = estimatedValue;
        estimatedNumberOfTrees = Offset.getEstimatedNumberOfTrees(estimatedValue);
        estimatedCarbonOffset = Offset.getEstimiatedCarbonOffset(estimatedNumberOfTrees);
    }
    const setMemSize = (value: any) => {
        device.memorySize = value.toUpperCase();
        updateEstimates(device);
        setDisabled(disableNextButton());
    }
    const setPhoneCarrier = (value: any) => {
        device.phoneCarrierName = value.toUpperCase();
        updateEstimates(device);
        setDisabled(disableNextButton());
    }
    const setPowerOn = (value: any) => {
        device.powerOn = value.toLowerCase() == 'yes' ? true : false;
        updateEstimates(device);
        setDisabled(disableNextButton());
    }
    const setHasCracks = (value: any) => {
        device.hasCracks = value.toLowerCase() == 'no' ? true : false;
        updateEstimates(device);
        setDisabled(disableNextButton());
    }
    const setIsFunctional = (value: any) => {
        device.isFunctional = value.toLowerCase() == 'yes' ? true : false;
        updateEstimates(device);
        setDisabled(disableNextButton());
    }
    const setIsDeregistered = (value: any) => {
        device.isDeregistered = value.toLowerCase() == 'yes' ? true : false;
        if (!device.isDeregistered) {
            presentToast({
                header: 'Warning',
                message: 'Make sure your device has been removed from online account(s) and factory reset before donating in.',
                duration: 5000,
                position: 'bottom',
                cssClass: 'custom-toast',
                buttons: [{ text: 'Dismiss', role: 'cancel' }]
            });
        }
        setDisabled(disableNextButton());
    }
    const disableNextButton = () => {
        let enable = device.memorySize && device.phoneCarrierName && (device.powerOn != undefined)
            && (device.hasCracks != undefined) && (device.isFunctional != undefined) && (device.isDeregistered != undefined);
        return !enable;
    }
    const goNext = (e: any) => {
        e.preventDefault();
        LocalStorageData.addDevice(device);
        let nextUrl = '/donate/shipping-information';
        router.push(nextUrl, 'forward', 'push');
    }
    const addDevice = (e: any) => {
        e.preventDefault();
        LocalStorageData.addDevice(device);
        LocalStorageData.clearAll();
        router.push('/donate')
    }
    const goBack = (e: any) => {
        e.preventDefault();
        router.goBack();
        /* TODO figure out why goBack() doesn't work properly
        if (router.canGoBack()) {
            console.log('should go back..');
            router.goBack();
        }*/
    }
    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/analytics.js" });
    return (
        <IonPage>

            <Helmet>
                <title>Details of the {name} you are donating.</title>
                <meta property="og:title" content={"Details of the " + { name } + " you are donating."} />
                <meta property="og:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/donate_header.webp" />
                <meta property="og:url" content={"https://www.greenor.org/donate/" + { manufacturerName } + "/" + { deviceCategory } + "/" + name} />
                <meta property="og:description" content={"Details and condition of the " + {name} + " you are donating. The number of trees that will be planted depend on its value which determined by its condition."} />
                <meta name="twitter:title" content={"Details of the " + { name } + " you are donating."} />
                <meta name="twitter:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/donate_header.webp" />
                <meta name="twitter:url" content={"https://www.greenor.org/donate/" + { manufacturerName } + "/" + { deviceCategory } + "/" + name} />
                <meta name="twitter:card" content={"Details and condition of the " + { name } + " you are donating. The number of trees that will be planted depend on its value which determined by its condition."} />
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonProgressBar value={0.7} color='secondary'></IonProgressBar>
                <IonRow>
                    <IonCol size="2">
                        <IonImg src={model?.imageUrl} />
                    </IonCol>
                    <IonCol>
                        <DonateBreadcrumb manufacturerName={manufacturerName} deviceCategory={deviceCategory} modelName={model?.name}></DonateBreadcrumb>
                        <DeviceValueImpact estimatedNumberOfTrees={estimatedNumberOfTrees} estimatedCarbonOffset={estimatedCarbonOffset} ></DeviceValueImpact>
                        <SelectDeviceSize memorySizes={model?.availableMemorySizes} callback={setMemSize}></SelectDeviceSize>
                        <SelectDeviceCarrier carriers={carriers} callback={setPhoneCarrier}></SelectDeviceCarrier>
                        <DeviceCondition setPowerOn={setPowerOn} setIsFunctional={setIsFunctional} setHasCracks={setHasCracks} setIsDeregistered={setIsDeregistered} ></DeviceCondition>
                        <IonRow class="ion-align-items-end ion-text-end">
                            <IonCol>
                                <IonButton id='addDevice' onClick={goBack} color="light" href='#' size='large'>Back</IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton id='addDevice' onClick={addDevice} color="light" href='#' disabled={disabled} size='large'>Donate another</IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton id='nextBtn' onClick={goNext} color="secondary" href='#' disabled={disabled} size='large'>Next</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default SelectModel;
