export const Constants = {
    STRING_TYPES: {
        ALPHA: 'ALPHA',
        ALPHA_NUMERIC: 'ALPHA_NUMERIC',
        NUMBER: 'NUMBER'
    },
    RECEIPT_NUMBER_LENGTH: 8,
    SHIPPING_DEFAULT_COUNTRY: 'US',
    SHIPPING_LABEL_PLACEHOLDER_URL: 'https://greenor.s3.us-east-2.amazonaws.com/img/shipping_label_placeholder.png',
    SHIPPING_LABEL_API_URL: 'https://4gctyze2jne6atchx5mhh2jhoe0kmdkg.lambda-url.us-east-2.on.aws/',
    STRIPE_SIMPLE_INTENT_URL: 'https://ggnzmxtnsypsuzk6czz73xgd2q0bckhu.lambda-url.us-east-2.on.aws/',
    STRIPE_SUBSCRIPTION_URL: 'https://nigx2zajq7emilpxrzhhgwda3e0rfvxt.lambda-url.us-east-2.on.aws/',
    STRIPE_CREATE_CUSTOMER_URL: 'https://4ydkduvochzke6jmhc53n4qoda0okpjg.lambda-url.us-east-2.on.aws/',
    STRIPE_DONATION_PRICE_ID: 'price_1MAhRkKPCuKCurseLmxMesOs',
    STRIPE_DONATION_PRODUCT_ID_TEST: 'prod_MuWUd1hKXDIU6K',
    STRIPE_DONATION_PRODUCT_ID_PROD: 'prod_MuWVXquxm66DXd',
    STRIPE_PUBLISHABLE_KEY_TEST: 'pk_test_51LmpU4KPCuKCurseD3uEXJEfjlZdk3MvBPYLrd4keByqCAPl8pythzRrjxEnrHNPpf83enICeM4I0YViCVTfVsZe00pFiSdCRM',
    STRIPE_PUBLISHABLE_KEY_PROD: 'pk_live_51LmpU4KPCuKCurseyLue7VrbKU0ohSmJ9IQjjg8fGws1xfWseyxgA5V3yULsKra12wHemrqcf1BAKqhYHI5Lcbfl00KZIQgHcb'
}