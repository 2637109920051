import { API, graphqlOperation } from 'aws-amplify';
import { listGreenorShipments } from '../graphql/queries';
import { createGreenorShipment } from '../graphql/mutations';
import Address from '../models/Address';
import Device from '../models/Device';
import { StringGenerator } from '../utils/StringGenerator';

const ShipmentData = {

    listShipments: async () => {
        try {
            const donations = await API.graphql(graphqlOperation(listGreenorShipments, {
                limit: 20
            }));
            const rst = donations['data']['listGreenorShipments']['items'];
            return rst;
        } catch (err) {
            console.error('Error happened getting donations:', err);
            return [];
        }
    },
    createShipment: async (donationId: string, transaction: any) => {

        try {
            const created = await API.graphql(graphqlOperation(createGreenorShipment,
                {
                    input: {
                        donationId: donationId,
                        labelUrl: transaction.label_url,
                        rate: JSON.stringify(transaction.rate),
                        rateAmount: transaction.rate?.amount,
                        trackingNumber: transaction.tracking_number,
                        status: transaction.status,
                        trackingUrl: transaction.tracking_url_provider,
                        provider: transaction.rate.provider,
                        objectState: transaction.object_state,
                        isTest: transaction.test,
                        wholeObject: JSON.stringify(transaction),
                        currency: transaction.rate?.currency,
                        ETA: transaction.eta
                    }
                }));
            return created['data']['createGreenorShipment'];
        } catch (err) {
            return null;
        }
    }
}

export default ShipmentData;