import React from 'react';
import { IonText, IonIcon, IonPopover, IonContent } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';

interface DeviceValueImpactProps {
    estimatedNumberOfTrees: number;
    estimatedCarbonOffset: number;
}

const DeviceValueImpact: React.FC<DeviceValueImpactProps> = ({ estimatedNumberOfTrees, estimatedCarbonOffset }) => {
    return (
        <div className="ion-text-center">
            <IonText>
                <h3>Value in avocado trees</h3>
            </IonText>
            <IonText>
                <p className='trees'><span> {estimatedNumberOfTrees} </span> Avocado Trees</p>
            </IonText>
            <IonText>
                {estimatedCarbonOffset} tons of CO<sub>2</sub>
                <IonIcon ios={informationCircleOutline} id='carbonInfo' />
                <IonPopover trigger='carbonInfo' triggerAction='hover' alignment='center' mode='ios'>
                    <IonContent class="ion-padding">Carbon captured from the air over a 10 years period.</IonContent>
                </IonPopover>
            </IonText>
        </div>
    );
};

export default DeviceValueImpact;
