import { IonButtons, IonContent, IonHeader, IonCard, IonPage, IonToolbar, IonCardContent,
    IonImg, IonButton, IonCardHeader, IonCardTitle, IonRow, IonCol, IonProgressBar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { NavButtons } from '../components/NavButtons';
import { Footer } from '../components/Footer';
import { Helmet } from "react-helmet";
import DeviceModelsData from './../data/DeviceModelsData'
import Title from '../components/Title';
import DeviceModel from '../models/DeviceModel';
import ImportExternalScripts from './../hooks/ImportExternalScripts';
import './SelectModel.css';

const deviceModelsData = new DeviceModelsData();
const numberOfModelPerRow: number = 6;

const SelectModel: React.FC = () => {

    const { deviceCategory } = useParams<{ deviceCategory: string; }>();
    const { manufacturerName } = useParams<{ manufacturerName: string; }>();
    const [rowsOfModels, setRowsOfModels] = useState([] as any);

    const getRow = (models: Array<DeviceModel>, rowIndex: string) => {
        return <IonRow key={rowIndex} itemScope itemType='https://schema.org/ItemList'>
                {models.map((model, idx) => {
                    return (
                        <IonCol itemProp='itemListElement' itemScope itemType='https://schema.org/Product' key={idx}>
                            <IonCard class="ion-text-center" href={['/donate', model.manufacturerName.replace(/ /g, '-'), deviceCategory.replace(/ /g, '-'), model.name.replace(/ /g, '-')].join('/')}>
                                <IonImg itemProp='image' src={model.imageUrl} />
                                <IonCardHeader>
                                    <IonCardTitle itemProp='name'>{model.name.toLocaleUpperCase()}</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonButton fill="outline" slot="end" color='secondary' itemProp='url' href={['/donate', model.manufacturerName.replace(/ /g, '-'), deviceCategory.replace(/ /g, '-'), model.name.replace(/ /g, '-')].join('/')}>Select</IonButton>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>)
                })}
        </IonRow>
    }
    useEffect(() => {
        deviceModelsData.getModelsByManufacturerAndCategory(manufacturerName.toLocaleLowerCase(), 'phones')
            .then(results => {
                if (results) {
                    let filtered = results.filter(rst => {
                        return rst.category === deviceCategory;
                    });
                    let sorted = filtered.sort((a: any, b: any) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                    });
                    const rows: any = [];
                    for (let i = 0; i < sorted.length; i++) {
                        if (i % numberOfModelPerRow == 0) {
                            let row = getRow(sorted.slice(i, i + numberOfModelPerRow), i.toString());
                            rows.push(row);
                        }
                    }
                    setRowsOfModels(rows);
                }
            });
    }, [manufacturerName]);

    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/analytics.js" });
    return (
        <IonPage>

            <Helmet>
                <title>Select a model of {manufacturerName} {deviceCategory}.</title>
                <meta property="og:title" content={ "Select a model of " + {manufacturerName} + " " + {deviceCategory}} />
                <meta property="og:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/donate_header.webp" />
                <meta property="og:url" content={"https://www.greenor.org/donate/" + { manufacturerName } + "/" + { deviceCategory }} />
                <meta property="og:description" content="Select a model you are donating." />
                <meta name="twitter:title" content={"Select a model of " + { manufacturerName } + " " + { deviceCategory }} />
                <meta name="twitter:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/donate_header.webp" />
                <meta name="twitter:url" content={"https://www.greenor.org/donate/" + {manufacturerName} + "/" + {deviceCategory}} />
                <meta name="twitter:card" content="Select a model you are donating." />
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonProgressBar value={0.5} color='secondary'></IonProgressBar>
                <h3>Select a model</h3>
                { rowsOfModels }
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default SelectModel;
