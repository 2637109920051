import React from 'react';
import { IonRow, IonGrid, IonIcon } from '@ionic/react';
import { giftOutline, leafOutline, pricetagOutline, refreshOutline } from 'ionicons/icons';

const HowItWorksComponent: React.FC = () => {
    return (
        <IonGrid>
            <IonRow class="ion-justify-content-center ion-text-center">
                <h1>How it works:</h1>
            </IonRow>
            <IonRow class="ion-justify-content-center"><IonIcon ios={giftOutline} class='how-it-works-ion-icon' /></IonRow>
            <IonRow class="ion-justify-content-center">
                <h5>You donate a device</h5>
            </IonRow>
            <IonRow class="ion-justify-content-center">
                <div>
                    <ul>
                        <li>You tell us about your device</li>
                        <li>We assess its value equivalent to trees to be planted</li>
                        <li>We provide you with a free shipping label and you ship it</li>
                        <li>After we receive the device, we email you a donation receipt for tax deduction of the value in dollars</li>
                    </ul>
                </div>
            </IonRow>
            <IonRow class="ion-justify-content-center"><IonIcon ios={refreshOutline} class='how-it-works-ion-icon' /></IonRow>
            <IonRow class="ion-justify-content-center ion-text-center">
                <h5>If the device can be repaired, we refurbish it otherwise we recycle it.</h5>
            </IonRow>
            <IonRow class="ion-justify-content-center"><IonIcon ios={pricetagOutline} class='how-it-works-ion-icon' /></IonRow>
            <IonRow class="ion-justify-content-center ion-text-center">
                <h5>We resell refurbished device.</h5>
            </IonRow>
            <IonRow class="ion-justify-content-center ion-text-center"><IonIcon ios={leafOutline} class='how-it-works-ion-icon' /></IonRow>
            <IonRow class="ion-justify-content-center ion-text-center">
                <h5>We use proceeds from reselling the device to plant fruit trees for smallholder farmers.</h5>
            </IonRow>
        </IonGrid>
    );
};

export default HowItWorksComponent;
