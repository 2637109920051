import { IonButtons, IonContent, useIonToast, IonHeader, IonList, IonItem, IonLabel, IonInput, IonPage, IonToolbar, IonButton, IonTextarea } from '@ionic/react';
import { NavButtons } from '../components/NavButtons';
import { Footer } from '../components/Footer';
import Title from '../components/Title';
import EmailUtil from '../utils/EmailUtil';
import Helper from '../utils/Helper';
import ContactUsData from '../data/ContactUsData';
import { useState } from 'react';
import { Helmet } from "react-helmet";
import ImportExternalScripts from './../hooks/ImportExternalScripts';

const contactUsData = new ContactUsData();

const Contact: React.FC = () => {

    const [presentToast] = useIonToast();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const validate = () => {
        let isValid = true;
        if (name.length <= 2) {
            isValid = false;
            Helper.showErrorMessage(true, 'nameErrorMessage');
        } else {
            Helper.showErrorMessage(false, 'nameErrorMessage');
        }

        if (!EmailUtil.validate(email)) {
            isValid = false;
            Helper.showErrorMessage(true, 'emailErrorMessage');
        } else {
            Helper.showErrorMessage(false, 'emailErrorMessage');
        }

        if (subject.length <= 3) {
            isValid = false;
            Helper.showErrorMessage(true, 'subjectErrorMessage');
        } else {
            Helper.showErrorMessage(false, 'subjectErrorMessage');
        }

        if (message.length <= 6) {
            isValid = false;
            Helper.showErrorMessage(true, 'messageErrorMessage');
        } else {
            Helper.showErrorMessage(false, 'messageErrorMessage');
        }

        return isValid;
    }
    const updateName = (e: any) => {
        setName(e.target.value);
    }
    const updateEmail = (e: any) => {
        setEmail(e.target.value);
    }
    const updatePhone = (e: any) => {
        setPhoneNumber(e.target.value);
    }
    const updateSubject = (e: any) => {
        setSubject(e.target.value);
    }
    const updateMessage = (e: any) => {
        setMessage(e.target.value);
    }
    const resetForm = () => {
        setName('');
        setEmail('');
        setPhoneNumber('');
        setSubject('');
        setMessage('');
    }
    const send = (e: any) => {
        e.preventDefault();
        if (validate()) {
            contactUsData.createContact(email, name, phoneNumber, subject, message)
                        .then(rst => {
                            resetForm();
                            presentToast({
                                header: 'Message received',
                                message: 'Thank you for contacting us; we will get back to you within 24 hours.',
                                duration: 5000,
                                position: 'bottom',
                                cssClass: 'custom-toast',
                                color: 'success',
                                buttons: [{ text: 'Dismiss', role: 'cancel' }]
                            });
                        }).catch(err => {
                            // console.error('Error trying to send message: ', err)
                        });
        }
    }
    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/analytics.js" });
    return (
        <IonPage>

            <Helmet>
                <title>Contact us</title>
                <meta property="og:title" content="Contact us" />
                <meta property="og:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/lone_tree.webp" />
                <meta property="og:url" content="https://www.greenor.org/contact" />
                <meta property="og:description" content="If you have any question, suggestion or you just want to talk to us, don't hesitate to send us a message." />
                <meta name="twitter:title" content="Contact us" />
                <meta name="twitter:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/lone_tree.webp" />
                <meta name="twitter:url" content="https://www.greenor.org/contact" />
                <meta name="twitter:card" content="If you have any question, suggestion or you just want to talk to us, don't hesitate to send us a message." />
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className='ion-padding'>
                <h1>Contact us</h1>
                <form>
                    <IonList>
                        <IonItem>
                            <IonLabel position="stacked">
                                Name * <span className='error-message' id='nameErrorMessage'>(A name is required)</span>:
                            </IonLabel>
                            <IonInput value={name} onIonChange={updateName} type='text' placeholder='Enter your full name'></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">
                                Email * <span className='error-message' id='emailErrorMessage'>(A name is required)</span>:
                            </IonLabel>
                            <IonInput value={email} onIonChange={updateEmail} type='email' placeholder='Enter your email'></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">
                                Phone number:
                            </IonLabel>
                            <IonInput value={phoneNumber} onIonChange={updatePhone} type='text' placeholder='Enter your phone number'></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">
                                Subject * <span className='error-message' id='subjectErrorMessage'>(A subject is required)</span>:
                            </IonLabel>
                            <IonInput value={subject} onIonChange={updateSubject} type='text' placeholder='Enter message subject'></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">
                                Message * <span className='error-message' id='messageErrorMessage'>(A message is required)</span>:
                            </IonLabel>
                            <IonTextarea value={message} onIonChange={updateMessage} placeholder='Talk to us'></IonTextarea>
                        </IonItem>
                    </IonList>

                    <IonButton onClick={send} size='large' href='#' color='secondary'>Send</IonButton>

                </form>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default Contact;
