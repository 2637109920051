import { Constants } from './Constants';

/**
 * Generates a random code based on params passed in
 * A pattern is the format of ****-***-**** where * represent a char of type StringType and - a constant char
 * eg. for the pattern above if StringType was alphaNumeric NXP3-71W-PF25 would be valid if type was numeric 1234-567-8906 would be valid
 * - Note that * is a reserved keyword
 * - Note that when pattern is not empty its length has to be equal to length
 */

const alphabet: string = 'ABCDEFGHIJKLMNPQRSTUVWZ';
const numbers: string = '1234567890';

export class StringGenerator {

    // @ts-ignore
    public static gen(length: number, stringType: string, pattern: string = '', upperCase: boolean = true): string {

        if (pattern && pattern.length !== length) {
            throw 'Length param should equal to pattern or pass in an empty patter.';
        }

        let code: string = '';
        let base: string = this.getBaseString(stringType);

        if (pattern) {
            code = this.genPatternCode(pattern, base);
        } else {
            code = this.genCode(length, base);
        }

        return code;
    }

    static genPatternCode(pattern: string, base: string) {

        let code = '';
        for (let i = 0; i < pattern.length; i++) {

            let index = this.getRandomInt(base.length);
            let char = pattern[i];
            if (char === '*') {
                code += base[index];
            } else {
                code += char;
            }
        }

        return code;
    }

    static genCode(length: number, base: string) {

        let code = '';
        while (length > 0) {
            let index = this.getRandomInt(base.length);
            code += base[index];
            length -= 1;
        }

        return code;
    }

    static getRandomInt(max: number) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    static getBaseString(stringType: string) {

        let base: string = '';
        if (stringType === Constants.STRING_TYPES.ALPHA_NUMERIC) {
            base = alphabet + numbers;
        } else if (stringType === Constants.STRING_TYPES.ALPHA) {
            base = alphabet;
        } else if (stringType === Constants.STRING_TYPES.NUMBER) {
            base = numbers;
        }

        return base;
    }
}


