import React from 'react';
import DeviceValueImpact from './../components/DeviceValueImpact';
import Offset from './../utils/Offset';
import './AmountImpact.css';

interface ImpactProps {
    amount: number;
}

const AmountImpact: React.FC<ImpactProps> = ({ amount }) => {

    amount = !isNaN(amount) ? amount : 0;
    let estimatedNumberOfTrees = Offset.getEstimatedNumberOfTrees(amount);
    let estimatedCarbonOffset = Offset.getEstimiatedCarbonOffset(estimatedNumberOfTrees);
    let numberOfFamilies = Math.round(estimatedNumberOfTrees / 2);
    let kids = numberOfFamilies * 3;

    return (
        <>
            <h3>Farmers' Families to be helped: <span className='digits'>{numberOfFamilies}</span></h3>
            <h3>Kids at risk of malnutrition to be helped: <span className='digits'>{kids}</span></h3>
            <DeviceValueImpact estimatedNumberOfTrees={estimatedNumberOfTrees} estimatedCarbonOffset={estimatedCarbonOffset}></DeviceValueImpact>
        </>
    );
};

export default AmountImpact;
