import React from 'react';
import { IonBreadcrumbs, IonBreadcrumb } from '@ionic/react';

interface DonateBreadcrumbProps {
    manufacturerName: string;
    deviceCategory: string;
    modelName?: string;
}

const DonateBreadcrumb: React.FC<DonateBreadcrumbProps> = ({ manufacturerName, deviceCategory, modelName }) => {
    return (
        <IonBreadcrumbs>
            <IonBreadcrumb href={'/donate/' + manufacturerName + '/' + deviceCategory}>DONATING {manufacturerName.toLocaleUpperCase()}</IonBreadcrumb>
            <IonBreadcrumb disabled>{modelName?.toLocaleUpperCase()}</IonBreadcrumb>
        </IonBreadcrumbs>
    );
};

export default DonateBreadcrumb;
