
const emailRegexFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const EmailUtil = {
    validate: (email: any) => {
        if (!email || !email.match(emailRegexFormat)) {
            return false;
        } else {
            return true;
        }
    }
}

export default EmailUtil;