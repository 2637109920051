import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonSelect, IonSelectOption, IonLabel, IonPage, IonRow, IonTitle, IonToolbar, IonButton, IonList, IonItem } from '@ionic/react';
import { NavButtons } from '../components/NavButtons';
import { Footer } from '../components/Footer';
import { useEffect, useState } from 'react';
import Title from '../components/Title';
import './Page.css';

import DeviceModelsData from '../data/DeviceModelsData';
import DeviceModelInputsComponent from '../components/DeviceModelInputs';
import ManufacturersData from '../data/ManufacturersData';
import DeviceModel from '../models/DeviceModel';
import Manufacturer from '../models/Manufacturer';
import Helper from '../utils/Helper';

const deviceModelData = new DeviceModelsData();
const manufacturerData = new ManufacturersData();

const imageUrlRegexFormat = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif|png|svg|webp))/;
const initlitializeModelOject = () => {
    return {
        name: '',
        manufacturer: '',
        category: '',
        imageUrl: '',
        availableColors: '',
        availableMemorySizes: ''
    }
}

const DeviceModelCRUD: React.FC = () => {

    const [models, setModels] = useState(new Array<DeviceModel>());
    const [manufacturers, setManufacturers] = useState(new Array<Manufacturer>());
    const [modelObject, setModelObject] = useState(initlitializeModelOject());
    const [currentManufacturer, setCurrentManufacturer] = useState('apple');
    const [availableSizes, setAvailableSizes] = useState([]);
    const [valueEstimatesByMemory, setValueEstimatesByMemory] = useState({});

    useEffect(() => {
        deviceModelData.getModelsByManufacturerAndCategory(currentManufacturer, 'phones')
                            .then(results => {
                                setModels(results);
                            });
    }, [currentManufacturer]);

    useEffect(() => {
        manufacturerData.getManufacturers()
                    .then(results => {
                        setManufacturers(results);
                    });
    }, []);

    const validateModel = (model: any): boolean => {

        let isValid = true;
        if (!model.name || model.name.trim().length < 4) {
            isValid = false;
            Helper.showErrorMessage(true, 'nameErrorMessage');
        } else {
            Helper.showErrorMessage(false, 'nameErrorMessage');
        }
        if (!model.category || model.category.trim().length < 4) {
            isValid = false;
            Helper.showErrorMessage(true, 'categoryErrorMessage');
        } else {
            Helper.showErrorMessage(false, 'categoryErrorMessage');
        }
        if (!model.manufacturer || model.manufacturer.trim().length < 2) {
            isValid = false;
            Helper.showErrorMessage(true, 'manufacturerErrorMessage');
        } else {
            Helper.showErrorMessage(false, 'manufacturerErrorMessage');
        }
        const isValidURL = model.imageUrl.match(imageUrlRegexFormat)
        if (!model.imageUrl || !isValidURL) {
            isValid = false;
            Helper.showErrorMessage(true, 'urlErrorMessage');
        } else {
            Helper.showErrorMessage(false, 'urlErrorMessage');
        }
        if (!model.availableColors || model.availableColors.trim().length < 4) {
            isValid = false;
            Helper.showErrorMessage(true, 'colorErrorMessage');
        } else {
            Helper.showErrorMessage(false, 'colorErrorMessage');
        }
        if (model.availableMemorySizes) {
            const sizes = model.availableMemorySizes.split(',');
            for(let i=0; i<sizes.length; i++) {
                if (isNaN(sizes[i])) {
                    isValid = false;
                    Helper.showErrorMessage(true, 'sizeErrorMessage');
                    break;
                } else {
                    Helper.showErrorMessage(false, 'sizeErrorMessage');
                }
            }
        } else {
            isValid = false;
            Helper.showErrorMessage(true, 'sizeErrorMessage');
        }
        if (model.availableMemorySizes) {
            const sizez = model.availableMemorySizes.split(',');
            for(var i=0; i<sizez.length; i++) {
                if (valueEstimatesByMemory[sizez[i]]) {
                    if (isNaN(Number.parseInt(valueEstimatesByMemory[sizez[i]]['lower'])) || isNaN(Number.parseInt(valueEstimatesByMemory[sizez[i]]['middle']))
                        || isNaN(Number.parseInt(valueEstimatesByMemory[sizez[i]]['upper']))) {
                        isValid = false;
                        Helper.showErrorMessage(true, 'sizeErrorMessage');
                        break;
                    } else {
                        Helper.showErrorMessage(false, 'sizeErrorMessage');
                    }
                } else {
                    isValid = false;
                    Helper.showErrorMessage(true, 'sizeErrorMessage');
                }
            }
        }
        return isValid;
    }
    const save = (e: any) => {
        e.preventDefault();
        const isValid = validateModel(modelObject);
        if (!isValid) {
            return;
        } else {
            const model = {
                name: modelObject.name.toLocaleLowerCase(),
                category: modelObject.category.toLocaleLowerCase(),
                manufacturerName: modelObject.manufacturer.toLocaleLowerCase(),
                imageUrl: modelObject.imageUrl,
                availableColors: modelObject.availableColors.split(','),
                availableMemorySizes: modelObject.availableMemorySizes.split(','),
                valueEstimatesByMemory: JSON.stringify(valueEstimatesByMemory)
            }
            deviceModelData.createModel(model)
                        .then(result => {
                            setModelObject(initlitializeModelOject());
                            let categorySelectElement = document.querySelector('#categorySelect');
                            if (categorySelectElement) {
                                categorySelectElement['value'] = '';
                            }
                            let manufacturerSelectElement = document.querySelector('#manufacturerSelect');
                            if (manufacturerSelectElement) {
                                manufacturerSelectElement['value'] = '';
                            }
                        });
        }
    }

    const updateName = (e: any) => {
        modelObject.name = e.target.value;
    }
    const updateCategory = (e: any) => {
        modelObject.category = e.target.value;
    }
    const updateManufacturer = (e: any) => {
        modelObject.manufacturer = e.target.value;
    }
    const updateImageUrl = (e: any) => {
        modelObject.imageUrl = e.target.value;
    }
    const updateAvailableColors = (e: any) => {
        modelObject.availableColors = e.target.value;
    }
    const updateAvailableMemorySizes = (e: any) => {
        modelObject.availableMemorySizes = e.target.value;
        if (e.target.value) {
            const sizes = e.target.value.split(',');
            setAvailableSizes(sizes);
        } else {
            setValueEstimatesByMemory({});
            setAvailableSizes([]);
        }
    }
    const updateSizesValue = (e: any) => {
        const val = e.target.value;
        const id = e.target.id;
        const indexes = id.split('-');
        if (indexes.length > 1) {
            if (valueEstimatesByMemory[indexes[0]]) {
                valueEstimatesByMemory[indexes[0]][indexes[1]] = val;
            } else {
                valueEstimatesByMemory[indexes[0]] = {};
                valueEstimatesByMemory[indexes[0]][indexes[1]] = val;
            }
        }
        setValueEstimatesByMemory(valueEstimatesByMemory);
    }
    const updateListManufacturer = (e: any) => {
        const manu = e.target.value;
        setCurrentManufacturer(manu);
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Models</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <form>
                                <DeviceModelInputsComponent deviceModelObject={modelObject} updateName={updateName} updateCategory={updateCategory} availableSizes={availableSizes}
                                    updateManufacturer={updateManufacturer} updateImageUrl={updateImageUrl} updateAvailableColors={updateAvailableColors} updateAvailableMemorySizes={updateAvailableMemorySizes} updateSizesValue={updateSizesValue} />
                                <IonRow class="ion-justify-content-center ion-align-items-center">
                                    <IonCol size='4' class="ion-justify-content-center">
                                        <IonButton onClick={save} size='large' href='#' color='secondary'>Save</IonButton>
                                    </IonCol>
                                </IonRow>
                            </form>
                        </IonCol>
                        <IonCol>
                            <IonRow>
                                <IonItem>
                                    <IonLabel position="stacked">
                                        Manufacturer * <span className='error-message' id='manufacturerErrorMessage'>(A manufacturer is required)</span>:
                                    </IonLabel>
                                    <IonSelect selectedText={currentManufacturer} onIonChange={updateListManufacturer} placeholder='Select Manufacturer'>
                                        {manufacturers.map((manu) => {
                                            return (<IonSelectOption value={manu.name} key={manu.name}>{manu.name}</IonSelectOption>)
                                        })}
                                    </IonSelect>
                                </IonItem>
                            </IonRow>
                            <IonRow>
                                <IonTitle>List of models for {currentManufacturer.toLocaleUpperCase()}</IonTitle>
                            </IonRow>
                            <IonRow>
                                <IonList>
                                    {models.map(model => {
                                        return (<IonItem key={model.name}>{model.name} | {model.manufacturerName} | {model.category} </IonItem>)
                                    })}
                                </IonList>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default DeviceModelCRUD;
