import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonItemDivider, IonPage, IonRow, IonToolbar } from '@ionic/react';
import { NavButtons } from './../../components/NavButtons';
import { Footer } from './../../components/Footer';
import Title from './../../components/Title';
import { Helmet } from "react-helmet";
import ImportExternalScripts from './../../hooks/ImportExternalScripts';

const ClimateJustice: React.FC = () => {

    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/analytics.js" });
    return (
        <IonPage>

            <Helmet>
                <title>Climate Justice</title>
                <meta property="og:title" content="Climate Justice" />
                <meta property="og:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/climate_justice.jpeg" />
                <meta property="og:url" content="https://www.greenor.org/blog/climate-justice" />
                <meta property="og:description" content="Climate Justice." />
                <meta name="twitter:title" content="Climate Justice" />
                <meta name="twitter:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/climate_justice.jpeg" />
                <meta name="twitter:url" content="https://www.greenor.org/blog/climate-justice" />
                <meta name="twitter:card" content="Climate Justice." />
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className='ion-padding'>
                <IonGrid>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <h2>Climate Justice</h2>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <p>
                                Climate justice is the principle that all people, regardless of their socioeconomic status or location, have the right to a safe and healthy environment, and that those who contribute the least to climate change should not bear a disproportionate burden of its effects. This concept is becoming increasingly important in the fight against climate change, as the effects of this global crisis are felt more and more disproportionately by marginalized and vulnerable communities.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow class="agroforestry">
                        <IonCol>
                            <IonImg src='https://greenor.s3.us-east-2.amazonaws.com/img/climate_justice.jpeg'></IonImg>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <p>
                                Climate change is a global challenge that affects everyone, but its effects are not equally distributed. Some communities and individuals are more vulnerable to the effects of climate change, and they are more likely to suffer from its negative consequences. For example, low-income communities and communities of color are often disproportionately affected by natural disasters, such as hurricanes and floods, and they are more likely to experience health impacts, such as heat stress and respiratory problems.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <p>
                                At the same time, some individuals and communities have more resources and support to adapt to climate change and protect themselves from its effects. For example, wealthier communities are often better able to afford technology and infrastructure that can help them cope with extreme weather events, such as sea walls and storm shelters. They are also more likely to have access to health care and other services that can help them recover from the effects of climate change.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <p>
                                Climate justice is about addressing these inequities and ensuring that everyone has the resources and support they need to adapt to climate change and thrive. This means providing vulnerable communities with the technology and infrastructure they need to protect themselves from the effects of climate change, such as flood control systems and early warning systems for extreme weather events. It also means providing these communities with education and training on how to adapt to climate change, and supporting their efforts to advocate for their rights and needs.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <p>
                                Climate justice is also about recognizing that the causes of climate change are not equally distributed. The wealthy and industrialized countries are responsible for the majority of the greenhouse gas emissions that are causing climate change while developing countries and low-income communities are the least responsible and the most vulnerable to its effects. Climate justice means taking steps to address this imbalance and supporting the efforts of developing countries and low-income communities to reduce their emissions and adapt to climate change.
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-content-justify-center ion-text-center agroforestry'>
                        <IonCol>
                            <p>
                                Climate justice is about recognizing the unequal effects of climate change and the unequal ability of individuals and communities to adapt to these effects. It is about ensuring that everyone has the resources and support they need to cope with the effects of climate change and thrive in a changing world. It is about addressing the causes and consequences of climate change in a fair and equitable way.
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default ClimateJustice;
