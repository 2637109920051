import { IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonRow, IonCol, IonGrid, IonList, IonItem } from '@ionic/react';

import { NavButtons } from '../components/NavButtons';
import { Footer } from '../components/Footer';
import { Helmet } from "react-helmet";
import './Donate.css';

import ManufacturersData from '../data/ManufacturersData';
import Title from '../components/Title';
import { useEffect, useState } from 'react';
import DeviceModelsData from './../data/DeviceModelsData'

const manufacturersData = new ManufacturersData();
const deviceModelsData = new DeviceModelsData();

const Sitemap: React.FC = () => {

    const [modelsByManufacturerCategories, setModelsByManufacturerCategories] = useState(new Array());
    const allModels = new Map();

    useEffect(() => {

        manufacturersData.getManufacturers()
            .then((rst) => {
                for (let i = 0; i < rst.length; i++) {
                    if (rst[i].isActive) {
                        const manufacturer = rst[i];
                        const manufacturerName = manufacturer.name;
                        deviceModelsData.getModelsByManufacturerAndCategory(manufacturerName.toLocaleLowerCase(), 'phones')
                            .then(results => {
                                if (results) {
                                    let sorted = results.sort((a: any, b: any) => {
                                        if (a.name < b.name) {
                                            return -1;
                                        }
                                    });
                                    let modelList = new Array();
                                    allModels.set(manufacturerName, sorted);
                                    let entries = allModels.entries();
                                    let entry = entries.next();
                                    while (entry.value) {
                                        modelList.push({'name': entry.value[0], 'value': entry.value[1]});
                                        entry = entries.next();
                                    }
                                    setModelsByManufacturerCategories(modelList);
                                }
                            });
                    }
                }
            });
    }, []);

    return (
        <IonPage>

            <Helmet>
                <title>Sitemap: Greenor.org</title>
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid>
                    <IonRow class='ion-text-center'>
                        <IonCol>
                            <h1>Sitemap</h1>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonList>
                                <IonItem>
                                    <a href='https://www.greenor.org/'>Greenor.org</a>
                                </IonItem>
                                <IonItem>
                                    <a href='https://www.greenor.org/how-it-works'>How it works</a>
                                </IonItem>
                                <IonItem>
                                    <a href='https://www.greenor.org/donate'>Donate</a>
                                </IonItem>
                                <IonItem>
                                    <a href='https://www.greenor.org/faq'>FAQ</a>
                                </IonItem>
                                <IonItem>
                                    <a href='https://www.greenor.org/contact'>Contact us</a>
                                </IonItem>
                                <IonItem>
                                    <a href='https://www.greenor.org/agroforestry'>Agroforestry</a>
                                </IonItem>
                                <IonItem>
                                    <a href='https://www.greenor.org/fruit-trees'>Fruit trees</a>
                                </IonItem>
                                <IonItem>
                                    <a href='https://www.greenor.org/blog/climate-justice'>Climate Justice</a>
                                </IonItem>
                                <IonItem>
                                    <a href='https://www.greenor.org/blog/climate-justice-for-smallholder-farmers'>Climate Justice for Smallholder Farmers</a>
                                </IonItem>
                            </IonList>
                        </IonCol>
                    </IonRow>
                    {modelsByManufacturerCategories.map((val, key) => {
                        return (<IonRow key={key} class='ion-text-center'>
                            <IonCol>
                                <h3>
                                    <a href={"https://www.greenor.org/donate/" + val.name + "/phones"}>{val.name.toLocaleUpperCase()}</a>
                                </h3>
                                <IonList>
                                    {val.value.map((v, k) => {
                                        return (<IonItem key={k}>
                                            <a href={"https://www.greenor.org/donate/" + v.manufacturerName + "/" + v.category + "/" + v.name.replace(/ /g, '-')}>Donate {v.name.toLocaleUpperCase()}</a>
                                        </IonItem>)
                                    })}
                                </IonList>
                            </IonCol>
                        </IonRow>
                            )
                    })}
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default Sitemap;
