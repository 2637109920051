import React from 'react';
import { IonText, IonSegment, IonSegmentButton } from '@ionic/react';
import YesNoSegment from './YesNoSegment';

interface SelectDeviceSizeProps {
    setPowerOn: Function;
    setIsFunctional: Function;
    setHasCracks: Function;
    setIsDeregistered: Function;
}

const DeviceCondition: React.FC<SelectDeviceSizeProps> = ({ setPowerOn, setIsFunctional, setHasCracks, setIsDeregistered }) => {
    return (
        <div className="section">
            <IonText>
                <h5>Device condition:</h5>
            </IonText>

            <p>Does the device power on?</p>
            <YesNoSegment callback={setPowerOn}></YesNoSegment>

            <p>Is the device fully functional?</p>
            <YesNoSegment callback={setIsFunctional}></YesNoSegment>

            <p>Is the device free of any cracks?</p>
            <YesNoSegment callback={setHasCracks}></YesNoSegment>

            <p>Has your device been deregistered from your account (eg. iCloud) and factory reset?</p>
            <YesNoSegment callback={setIsDeregistered}></YesNoSegment>

        </div>
    );
};

export default DeviceCondition;
