import {
    IonButtons, IonContent, IonHeader, IonCard, IonPage, IonTitle, IonToolbar, IonCardContent,
    IonImg, IonButton, IonCardHeader, IonCardTitle, IonRow, IonCol, IonProgressBar, IonGrid
} from '@ionic/react';

import { NavButtons } from '../components/NavButtons';
import { Footer } from '../components/Footer';
import { Helmet } from "react-helmet";
import './Donate.css';

import ManufacturersData from '../data/ManufacturersData';
import { useParams } from 'react-router';
import Title from '../components/Title';
import { useEffect, useState } from 'react';
import Manufacturer from '../models/Manufacturer';
import ImportExternalScripts from './../hooks/ImportExternalScripts';

const manufacturersData = new ManufacturersData();

const SelectManufacturer: React.FC = () => {

    const [manufacturers, setManufacturers] = useState(new Array<Manufacturer>());
    useEffect(() => {

        manufacturersData.getManufacturers()
                        .then((rst) => {
                            const actives: any = [];
                            for(let i=0; i<rst.length; i++) {
                                    if (rst[i].isActive) {
                                        actives.push(rst[i]);
                                    }
                            }
                            setManufacturers(actives);
                        });
    }, []);
    // TODO filter manufacturers by device category
    const { deviceCategory } = useParams<{ deviceCategory: string; }>();

    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/analytics.js" });
    return (
        <IonPage>

            <Helmet>
                <title>Select device manufacturer for the device you are donating.</title>
                <meta property="og:title" content="Select device manufacturer for the device you are donating." />
                <meta property="og:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/apple-logo-transparent.png" />
                <meta property="og:url" content={"https://www.greenor.org/donate/" + deviceCategory} />
                <meta property="og:description" content="Donate a device for a greener and more equitable planet." />
                <meta name="twitter:title" content="Donate electronic devices to plant trees that protect the environment and help smallholder farmers." />
                <meta name="twitter:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/apple-logo-transparent.png" />
                <meta name="twitter:url" content={"https://www.greenor.org/donate/" + deviceCategory} />
                <meta name="twitter:card" content="Select device manufacturer for the device you are donating." />
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonProgressBar value={0.3} color='secondary'></IonProgressBar>
                <IonGrid>
                    <IonRow>
                        <IonTitle>
                            <h3>Select a manufacturer</h3>
                        </IonTitle>
                    </IonRow>
                    <IonRow>
                        {manufacturers.map((man, idx) => {
                            return (
                                <IonCol key={idx}>
                                    <IonCard class="ion-text-center" href={'/donate/' + man.name + '/' + deviceCategory}>
                                        <IonImg src={man.logoUrl} />
                                        <IonCardHeader>
                                            <IonCardTitle>{man.name.toLocaleUpperCase()}</IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <IonButton fill="outline" slot="end" color='secondary'>Select</IonButton>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>)
                        })}
                    </IonRow>
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default SelectManufacturer;
