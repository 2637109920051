class Address {
    email?: string;
    fullName?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipCode?: number;
    phone?: string;
}

export default Address;