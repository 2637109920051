import { IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import { NavButtons } from '../components/NavButtons';
import { Footer } from '../components/Footer';
import Title from '../components/Title';
import './FAQ.css';
import { Helmet } from "react-helmet";
import FAQComponent from '../components/FAQ';
import ImportExternalScripts from './../hooks/ImportExternalScripts';

const FAQ: React.FC = () => {
    ImportExternalScripts({ url: "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85" });
    ImportExternalScripts({ url: "./scripts/analytics.js" });
    return (
        <IonPage>

            <Helmet>
                <title>FAQ</title>
                <meta property="og:title" content="Frequently asked questions." />
                <meta property="og:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/agroforestry.webp" />
                <meta property="og:url" content="https://www.greenor.org/faq" />
                <meta property="og:description" content="Answers to questions asked frequently." />
                <meta name="twitter:title" content="Frequently asked questions" />
                <meta name="twitter:image" content="https://greenor.s3.us-east-2.amazonaws.com/img/agroforestry.webp" />
                <meta name="twitter:url" content="https://www.greenor.org/faq" />
                <meta name="twitter:card" content="Answers to questions asked frequently." />
            </Helmet>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className='ion-padding'>
                <FAQComponent />
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default FAQ;
