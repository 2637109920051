const FetchData = {

    fetch: async (url: string, method='POST', data={}) => {
        let response = await fetch(url, {
            method: method,
            mode: 'cors',
            cache: 'no-cache',
            body: JSON.stringify(data),
        });
        return response.json();
    },
    test: async () => {
        let response = await fetch('https://uq5laedg6obhkcfbp4lm5fh73y0ydwly.lambda-url.us-east-2.on.aws/').then(rst => {
            console.log('Got test result: ', rst);
        }).catch( err => {
            console.error('Failed to get test result: ', err);
        });
        console.log('Response in test: ', response);
    }
}

export default FetchData;