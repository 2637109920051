import React, { useState } from 'react';
import { IonButton } from '@ionic/react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckout from './StripeCheckout';
import FetchData from '../data/FetchData';
import { Constants } from '../utils/Constants';
import './StripePayment.css';
import Donor from './Donor';
import Frequency from './../models/Frequency';

const stripePromise = loadStripe(Constants.STRIPE_PUBLISHABLE_KEY_PROD);
interface PaymentProps {
    amount: number;
    donationFrequency: Frequency;
    goBackToAmount: () => void;
    inHonorOf: string;
}

const StripePayment: React.FC<PaymentProps> = ({ amount, goBackToAmount, donationFrequency, inHonorOf }) => {

    const [clientSecret, setClientSecret] = useState('');
    const [options, setOptions] = useState({});
    const [subscriptionId, setSubscriptionId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerName, setCustomerName] = useState('');

    amount *= 100;

    const onCustomerCreated = (customerId: string, email: string, name: string) => {
        setCustomerId(customerId);
        setCustomerEmail(email);
        setCustomerName(name);
        if (clientSecret) {
            console.log('Client secret created already: ', clientSecret);
            return;
        }
        if (donationFrequency == Frequency.MONTHLY) {
            FetchData.fetch(Constants.STRIPE_SUBSCRIPTION_URL, 'POST', { customerId: customerId, amount: amount, productId: Constants.STRIPE_DONATION_PRODUCT_ID_PROD })
                .then(sub => {
                    setOptions({ clientSecret: sub['clientSecret'] });
                    setClientSecret(sub['clientSecret']);
                    setSubscriptionId(sub['subscriptionId']);
                    setCustomerName(name);
                });
        } else {
            FetchData.fetch(Constants.STRIPE_SIMPLE_INTENT_URL, 'POST', { amount: amount, customerId: customerId })
                .then((intent) => {
                    const clientSecret = intent.clientSecret;
                    setOptions({ clientSecret });
                    setClientSecret(clientSecret);
                });
        }
    }

    const appearance = { theme: 'stripe' };
    return (
        <>
            <h4>Donation: ${amount/100} USD</h4>
            <div>
                <Donor onCustomerCreated={onCustomerCreated} email={customerEmail} name={customerName} ></Donor>
            </div>

            <div>
                { clientSecret &&
                    <Elements options={options} stripe={stripePromise}>
                        <StripeCheckout donorEmail={customerEmail} donorName={customerName} donationAmount={amount} frequency={donationFrequency} inHonorOf={inHonorOf}></StripeCheckout>
                    </Elements>
                }
            </div>

            <div>
                <IonButton id='payment-back-button' color='light' onClick={goBackToAmount}>Back</IonButton>
            </div>
        </>
    );
};

export default StripePayment;
