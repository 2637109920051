import React from 'react';
import { IonRow, IonCol, IonLabel, IonInput, IonTitle } from '@ionic/react';

interface ValueEstimateProps {
    size: string;
    updateSizesValue: (e: any) => void;
}

const ValueEstimateComponent: React.FC<ValueEstimateProps> = ({ size, updateSizesValue }) => {
    return (
        <IonRow>
            <IonCol>
                <IonLabel position="stacked">
                    Low Value for {size}GB:
                </IonLabel>
                <IonInput type='text' onIonChange={updateSizesValue} placeholder='$' id={size + '-lower'}></IonInput>
            </IonCol>
            <IonCol>
                <IonLabel position="stacked">
                    Middle value for {size}GB:
                </IonLabel>
                <IonInput type='text' onIonChange={updateSizesValue} placeholder='$' id={size + '-middle'}></IonInput>
            </IonCol>
            <IonCol>
                <IonLabel position="stacked">
                    Upper value for {size}GB:
                </IonLabel>
                <IonInput type='text' onIonChange={updateSizesValue} placeholder='$' id={size + '-upper'}></IonInput>
            </IonCol>
        </IonRow>
    );
};

export default ValueEstimateComponent;
