import React from 'react';

const Body: React.FC = () => {

    return (
        <div id='page'>
            <div className="fh5co-loader"></div>

            <div className="container-wrap">

                <aside id="fh5co-hero">
                    <div className="flexslider">
                        <ul className="slides">
                            <li id='lone-tree'>
                                <div className="overlay-gradient"></div>
                                <div className="container-fluids">
                                    <div className="row">
                                        <div className="col-md-6 col-md-offset-3 slider-text">
                                            <div className="slider-text-inner text-center">
                                                <h1 className="white-text">e-Waste for a greener planet</h1>
                                                <h2 className="white-text">Donate electronic devices to plant trees
                                                </h2>
                                                <p>
                                                    <a className="btn btn-primary btn-demo" href="/donate">Donate Now</a>
                                                    {/*<a className='btn btn-learn'> Buy used </a>*/}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li id='plant-seedling'>
                                <div className="overlay-gradient"></div>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-md-offset-3 col-md-pull-3 slider-text">
                                            <div className="slider-text-inner">
                                                <h1 className="white-text">A used iphone 12 can help plant 40 mango trees</h1>
                                                    <h2 className="white-text">Offsetting 28 metric tons of C02 over 10 years</h2>
                                                    <p>
                                                        <a className="btn btn-primary btn-demo" href="/donate">Donate Now</a>
                                                        {/*<a className='btn btn-learn'>Buy used</a>*/}
                                                    </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li id='impact-home'>
                                <div className="overlay-gradient"></div>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-md-offset-3 col-md-push-3 slider-text">
                                            <div className="slider-text-inner">
                                                <h1 className="white-text">Mining minerals to manufacture electronics harm the environment</h1>
                                                    <h2 className="white-text">Electronic devices manufacturing has a detrimental impact on the environment </h2>
                                                    <p>
                                                        <a className="btn btn-primary btn-demo" href="/donate">Donate Now</a>
                                                        {/*<a className='btn btn-learn'>Buy used</a>*/}
                                                    </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li id='agro-home-top'>
                                <div className="overlay-gradient"></div>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-md-offset-3 col-md-push-3 slider-text">
                                            <div className="slider-text-inner">
                                                <h1 className="white-text">Agroforestry</h1>
                                                <h2 className="white-text">Trees are integrated within agricultural environments in a sustainable way that supports natural resources.</h2>
                                                <p>
                                                    <a className="btn btn-primary btn-demo" href="/donate">Donate Now</a>
                                                    {/*<a className='btn btn-learn'>Buy used</a>*/}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </aside >

                <div id="fh5co-services">
                    <div className="row">
                        <div className="col-md-4 text-center animate-box">
                            <div className="services">
                                <span className="icon">
                                    <i className="icon-cloud"></i>
                                </span>
                                <div className="desc">
                                    <h3><a href="/">Minerals mining</a></h3>
                                    <p>Mining menerals used in electronic devices is detrimental to environment; it pollutes the air and contaminate the soil,
                                        groundwater and surface water which affects the quality of human health and biodeiversity.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center animate-box">
                            <div className="services">
                                <span className="icon">
                                    <i className="icon-trash"></i>
                                </span>
                                <div className="desc">
                                    <h3><a href="/">Staggering e-waste </a></h3>
                                    <p>The US, alone, generated 6.92 million tons of e-waste, 46lbs/person, in 2019. 151 million phones are thrown away each year.
                                        And only less than 20% of that e-waste is recycled or resold for re-use.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center animate-box">
                            <div className="services">
                                <span className="icon">
                                    <i className="icon-food"></i>
                                </span>
                                <div className="desc">
                                    <h3><a href="/">Climate change and smallholder farmers</a></h3>
                                    <p>Climate change dispropotionaly affects subsistance farmers while they cause the least pollution;
                                        it's only fair to support them as we fight the climate change.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="fh5co-work" className="fh5co-light-grey">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="fh5co-blog animate-box">
                                <a href="/" className="blog-bg" id='agro-home'></a>
                                <div className="blog-text">
                                    <h3><a href="/">Agroforestry</a></h3>
                                    <p>Smallholder farmers are the most impacted by climate change and cause the least pollution. Trees are planted alongside crops,
                                        contributing to soil health by controlling erosion, retaining water, and increasing compost. That improves farmers' productivity and biodiversity.
                                        Fruit trees are also a source of cash for farmers and alleviate malnutrition in farmers' children.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fh5co-blog animate-box">
                                <a href="/" className="blog-bg" id='recycle-reuse'></a>
                                <div className="blog-text">
                                    <h3><a href="/">Recycle and reuse</a></h3>
                                    <p>Greenor collects used devices and recycles nonfunctioning ones and refurbishes functional ones for reuse;
                                        the proceeds from the recycled and refurbished devices are used to plant trees.
                                        Recycled minerals reduce mining activities and reused material reduces the demand for new devices.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fh5co-blog animate-box">
                                <a href="/" className="blog-bg" id='carbon-in-air'></a>
                                <div className="blog-text">
                                    <h3><a href="/">Carbon sequestration</a></h3>
                                    <p>Planted trees capture carbon from the air which positively affects the well-being and humans and biodiversity.
                                        Tree planting programs go hand-in-hand with other soil management and forest protection technics that reduce carbon in
                                        the atmosphere.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="fh5co-counter" className="fh5co-counters">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 text-center animate-box">
                            <p>Climate change is a complex existential threat to humanity that needs diverse creative solutions.</p>
                            </div>
                    </div>
                    <div className="row animate-box">
                        <div className="col-md-8 col-md-offset-2">
                            <div className="row">
                                <div className="col-md-3 text-center">
                                    <span className="fh5co-counter">6.9M</span>
                                    <span className="fh5co-counter-label">Tons of e-Waste in US</span>
                                </div>
                                <div className="col-md-3 text-center">
                                    <span className="fh5co-counter">$60B</span>
                                    <span className="fh5co-counter-label">e-Waste value</span>
                                </div>
                                <div className="col-md-3 text-center">
                                    <span className="fh5co-counter">20%</span>
                                    <span className="fh5co-counter-label">Recycled e-Waste</span>
                                </div>
                                <div className="col-md-3 text-center">
                                    <span className="fh5co-counter">150M</span>
                                    <span className="fh5co-counter-label">Phones discarded in US</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="fh5co-blog" className="blog-flex">
                    <div className="featured-blog" id='in-together'>
                        <div className="desc-t">
                            <div className="desc-tc">
                                <span className="featured-head">Climate education</span>
                                <h3><a href="/">We are in this together and any action counts.</a></h3>
                                <span>
                                    <a href="https://www.google.com/search?q=climate+change&sxsrf=ALiCzsYskUY6DXBUVhFgugiUY-__miPRGg%3A1662076037818&ei=hUQRY_K4MdufqtsPgcGnkAE&ved=0ahUKEwiyj8ve4_T5AhXbj2oFHYHgCRIQ4dUDCA4&uact=5&oq=climate+change&gs_lcp=Cgdnd3Mtd2l6EAMyBAgjECcyBAgjECcyBAgjECcyCggAELEDEIMBEEMyCwgAEIAEELEDEIMBMgsIABCABBCxAxCDATIICAAQgAQQsQMyCwgAEIAEELEDEIMBMgsIABCABBCxAxCDATILCAAQgAQQsQMQgwE6CggAEEcQ1gQQsAM6EAguEMcBENEDENQCELADEEM6BwgAELADEEM6EQguEIAEELEDEIMBEMcBENEDOgQIABBDOgUIABCRAjoICC4QsQMQgwE6CwguEIAEELEDENQCOggIABCABBDJAzoFCAAQkgM6DQguELEDEIMBENQCEEM6DggAEIAEELEDEIMBEMkDSgQIQRgASgQIRhgAUIUQWLwjYIAmaAVwAXgAgAFmiAG4CJIBBDEyLjKYAQCgAQHIAQrAAQE&sclient=gws-wiz#wptab=si:AC1wQDD_BJWaofJGLHob5kvZW8mhgnir6sCKzNuh14NLSyxFL25KuE0C1CyYlc4Ur0_MX2ErLQe-ATzz6haJTi4ESa_rUZjlNb-aFFCjZJ2Z8pnBPoelFHI83E1JugfYzbNrm45l1zQA" className="read-button" target="_blank">
                                        Learn More
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="blog-entry fh5co-light-grey">
                        <div className="row animate-box">
                            <div className="col-md-12">
                                <h2>Know more</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 animate-box">
                                <a href="https://www.un.org/en/climatechange/what-is-climate-change" target="_blank" className="blog-post">
                                    <span className="img" style={{ "background": "url(https://greenor.s3.us-east-2.amazonaws.com/img/blog-3.jpg)"}}></span>
                                    <div className="desc">
                                        <h3>What is climate change</h3>
                                        <span className="cat">UN</span>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-12 animate-box">
                                <a href="https://www.epa.gov/recycle/electronics-donation-and-recycling#why" target="_blank" className="blog-post">
                                    <span className="img" style={{ "background": "url(https://greenor.s3.us-east-2.amazonaws.com/img/blog-1.jpg)"}}></span>
                                    <div className="desc">
                                        <h3>Why donate or recycle electronics</h3>
                                        <span className="cat">EPA</span>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-12 animate-box">
                                <a href="https://www.dosomething.org/us/facts/11-facts-about-e-waste#:~:text=E%2Dwaste%20represents%202%25%20of,metals%20like%20gold%20or%20silver." target="_blank" className="blog-post">
                                    <span className="img" style={{ "background": "url(https://greenor.s3.us-east-2.amazonaws.com/img/blog-2.jpg)"}}></span>
                                    <div className="desc">
                                        <h3>Facts about e-Waste</h3>
                                        <span className="cat">DoSomething</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className="gototop js-top">
                <a href="#" className="js-gotop"><i className="icon-arrow-up2"></i></a>
            </div>
        </div>
);};

export default Body;
