import React from 'react';
import { IonFooter, IonToolbar } from '@ionic/react';

export const Footer: React.FC = () => (
    <>
        <IonFooter collapse="fade">
            <IonToolbar>
                <div id='page'>
                    <div className="container-wrap">
                        <footer id="fh5co-footer" role="contentinfo">
                            <div className="row">
                                <div className="col-md-3 fh5co-widget">
                                    <h4>About Greenor.org</h4>
                                    <p>Greenor.org is climate justice non-profit organization that takes electronic devices as a donation to plant trees; the trees capture CO<sub>2</sub> from the air and by adhering to agroforestry methodologies, improve the lives of smallholder farmers. All donations are tax deductible.</p>
                                </div>
                                <div className="col-md-3 col-md-push-1">
                                    <h4>Links</h4>
                                    <ul className="fh5co-footer-links">
                                        <li><a href="/agroforestry">Agroforestry</a></li>
                                        <li><a href="/fruit-trees">The trees</a></li>
                                        <li><a href="https://en.wikipedia.org/wiki/Carbon_sequestration" target='blank'>Carbon sequestration</a></li>
                                        <li><a href="/sitemap">Sitemap</a></li>
                                    </ul>
                                </div>

                                <div className="col-md-3 col-md-push-1">
                                    <ul className="fh5co-footer-links">
                                        <li><a href="/donate">Donate</a></li>
                                        <li><a href="/how-it-works">How it works</a></li>
                                        <li><a href="/faq">FAQ</a></li>
                                        <li><a href="/contact">Contact</a></li>
                                    </ul>
                                </div>

                                <div className="col-md-3">
                                    <h4>Contact Information</h4>
                                    <ul className="fh5co-footer-links">
                                        <li><span>156 Brady Creek,</span> <br /> <span>Austin TX 78141</span></li>
                                        <li><a href="mailto:earth@greenor.org">earth@greenor.com</a></li>
                                    </ul>
                                </div>

                            </div>

                            <div className="row copyright">
                                <div className="col-md-12 text-center">
                                    <p>
                                        <small className="block">&copy; 2022 Greenor.org. All Rights Reserved.</small>
                                    </p>
                                    <div>
                                        <ul className="fh5co-social-icons">
                                            <li><a href="https://twitter.com/greenor_org" target='blank'><i className="icon-twitter"></i></a></li>
                                            <li><a href="https://www.facebook.com/profile.php?id=100087298224653" target='blank'><i className="icon-facebook"></i></a></li>
                                            <li><a href="https://www.instagram.com/greenor_org/" target='blank'><i className="icon-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </IonToolbar>
        </IonFooter>

    </>
);