import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import { NavButtons } from '../components/NavButtons';
import { Footer } from '../components/Footer';
import Header from './home/Header';
import './home/css/style.css';
import './home/css/animate.css';
import './home/css/bootstrap.css';
import './home/css/flexslide.css';
import './home/css/icomoon.css';
import './home/css/magnific-popup.css';
import Body from './home/Body';
import ImportExternalScripts from './../hooks/ImportExternalScripts';
import Title from '../components/Title';

const urls = ["./scripts/jquery.min.js", "./scripts/jquery.easing.1.3.js", "./scripts/bootstrap.min.js",
    "./scripts/jquery.waypoints.min.js", "./scripts/jquery.flexslider-min.js", "./scripts/jquery.magnific-popup.min.js",
    "./scripts/magnific-popup-options.js", "./scripts/jquery.countTo.js", "./scripts/main.js",
    "https://www.googletagmanager.com/gtag/js?id=G-3JQLB1VL85", './scripts/analytics.js']

const Home: React.FC = () => {

    ImportExternalScripts({ url: "./scripts/jquery.min.js" });
    urls.forEach(url => {
        ImportExternalScripts({ url: url });
    });

    return (
        <IonPage>
            <Header />
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <NavButtons />
                    </IonButtons>
                    <Title />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <Body />
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default Home;
