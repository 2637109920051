import React from 'react';
import { IonSegment, IonSegmentButton } from '@ionic/react';

interface YesNoSegmentProps {
    callback: Function;
}

const YesNoSegment: React.FC<YesNoSegmentProps> = ({ callback }) => {
    return (
        <IonSegment onIonChange={e => callback(e.detail.value)} color='secondary'>
            <IonSegmentButton value='yes'>Yes</IonSegmentButton>
            <IonSegmentButton value='no'>No</IonSegmentButton>
        </IonSegment>
    );
};

export default YesNoSegment;
