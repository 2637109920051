import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { archiveOutline, archiveSharp, bookmarkOutline, cogOutline, cogSharp, heartOutline, heartSharp, helpOutline, helpSharp, leafOutline, leafSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp } from 'ionicons/icons';
import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Home',
    url: '/',
    iosIcon: leafOutline,
    mdIcon: leafSharp
  },
  {
    title: 'How it works',
    url: '/how-it-works',
    iosIcon: cogOutline,
    mdIcon: cogSharp
  },
  {
    title: 'Donate',
    url: '/donate',
    iosIcon: heartOutline,
    mdIcon: heartSharp
  },
  {
    title: 'FAQ',
    url: '/faq',
    iosIcon: helpOutline,
    mdIcon: helpSharp
  },
  {
    title: 'Contact',
    url: '/contact',
    iosIcon: mailOutline,
    mdIcon: mailSharp
  }
];

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay" side="end">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader >Greenor</IonListHeader>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} class='menu-ion-icon' />
                  <IonLabel className="menu-item">{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
