import React from 'react';
import { IonText, IonSegment, IonSegmentButton, IonLabel } from '@ionic/react';
import PhoneCarrier from '../models/PhoneCarrier';

interface SelectDeviceSizeProps {
    carriers: Array<PhoneCarrier>;
    callback: Function;
}

const SelectDeviceCarrier: React.FC<SelectDeviceSizeProps> = ({ carriers, callback }) => {
    return (
        <div className='section'>
            <IonText>
                <h5>Select carrier:</h5>
            </IonText>
            <IonSegment scrollable onIonChange={e => callback(e.detail.value)} color='secondary'>
                {carriers.map(carrier => {
                    return (
                        <IonSegmentButton key={carrier.name} value={carrier.name}>
                            <IonLabel>{carrier.name}</IonLabel>
                        </IonSegmentButton>
                    );
                })}
            </IonSegment>
        </div>
    );
};

export default SelectDeviceCarrier;
